import React from "react";
import styles from "./ProductCard.module.scss";
import {BLANK_IMAGE, getCurrencyLocally} from "../../../utils/constants";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {getNameByLanguage} from "../../../utils";
import {useSelector} from "react-redux";

const ProductRow = ({product, className}) => {

    const {t, i18n} = useTranslation();
    const business = useSelector(state => state.client.business);

    const image = product.images?.[0]?.link;
    return (
        <div className={classNames(styles.productCard, className)}>
            <div className={styles.image}
                 style={{backgroundImage: "url('" + (image || BLANK_IMAGE) + "')"}}/>
            <div className={styles.price}>
                {product.quantity}x
            </div>
            <div className={styles.content}>
                <p className={styles.title}>{getNameByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, product)}</p>
                <p className={styles.subtitle}>{product.price}{getCurrencyLocally()}</p>
            </div>
        </div>
    )
}

export default ProductRow;
