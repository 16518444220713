import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {validate} from "../../../utils/validation";
import {inputValidations} from "../../../utils/validation/configurations";
import {askForConfirmation, getLanguageLabel} from "../../../utils";
import {setLoading} from "../../../actions";
import {createTable, getTables} from "../../../actions/tables";
import {connect} from "react-redux";
import Input, {InputStyles} from "../../Input/Input";
import {addProductImage, createProduct, updateProduct} from "../../../actions/products";
import ProductImages from "../../ProductImages/ProductImages";

class CreateProductSidebar extends Component {

    state = {
        showValidation: false,
        product: {
            name: "",
            nameEn: "",
            price: "",
            preparingTime: "",
            description: "",
            descriptionEn: "",
            images: []
        },
        images: {
            imagesToRemove: [],
            imagesToAdd: []
        }
    }

    componentDidMount() {
        //
    }

    formIsInvalid = () => {
        const {product} = this.state;
        return validate(inputValidations.productNameInput, product.name) ||
            validate(inputValidations.productNameEnInput, product.nameEn) ||
            validate(inputValidations.productDescriptionInput, product.description) ||
            validate(inputValidations.productDescriptionEnInput, product.descriptionEn) ||
            validate(inputValidations.productPriceInput, product.price) ||
            validate(inputValidations.productDeliveryInput, product.preparingTime);
    }

    handleCreateProduct = async () => {
        const { t } = this.props;
        const { product, images } = this.state;

        if (this.formIsInvalid()) {
            this.setState({ showValidation: true });
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.createProduct(this.state.product).then(res => {
            const uploadPromises = images.imagesToAdd.map(image => {
                return this.props.addProductImage(res.data.id, image.file);
            });
            Promise.all(uploadPromises).then(results => {
                // results will be an array of responses from the addProductImage calls
                const newImages = results.map(res => res.data); // Assuming the response shape
                this.setState(prevState => ({
                    product: {
                        ...prevState.product,
                        images: [...prevState.product.images, ...newImages]
                    }
                }), () => {
                    this.props.setLoading(false);
                    this.props.onSubmit({ ...res.data, images: [...this.state.product.images, ...newImages] });
                });
            }).catch(error => {
                // Handle any errors here
                this.props.setLoading(false);
            });
        });
    }

    render() {
        const {t, user_details} = this.props;
        const {product, showValidation} = this.state;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{t("Create and Add Product")}</h2>
                    <div className="d-flex">
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>
                <Input
                    label={`${t("Name")} ${t("Primary language")}`}
                    id="productNameInput"
                    name="productNameInput"
                    value={product.name}
                    style={InputStyles.Material}
                    validations={[inputValidations.productNameInput, showValidation]}
                    onChange={(e) => this.setState({product: {...product, name: e.target.value}})}/>

                <Input
                    label={`${t("Name")} ${t("Secondary language")}`}
                    id="productNameInputEn"
                    name="productNameInputEn"
                    value={product.nameEn}
                    style={InputStyles.Material}
                    validations={[inputValidations.productNameEnInput, showValidation]}
                    onChange={(e) => this.setState({product: {...product, nameEn: e.target.value}})}/>

                <Input
                    label={t("Price")}
                    id="productPriceInput"
                    name="productPriceInput"
                    value={product.price}
                    style={InputStyles.Material}
                    validations={[inputValidations.productPriceInput, showValidation]}
                    onChange={(e) => this.setState({product: {...product, price: e.target.value}})}/>

                <Input
                    type="number"
                    label={t("Preparation time") + " (min)"}
                    id="productDeliveryInput"
                    name="productDeliveryInput"
                    value={product.preparingTime}
                    style={InputStyles.Material}
                    validations={[inputValidations.productDeliveryInput, showValidation]}
                    onChange={(e) => this.setState({product: {...product, preparingTime: e.target.value}})}/>

                <Input
                    type="textarea"
                    label={`${t("Description")} ${t("Primary language")}`}
                    id="productDescriptionInput"
                    name="productDescriptionInput"
                    value={product.description}
                    style={InputStyles.Material}
                    validations={[inputValidations.productDescriptionInput, showValidation]}
                    onChange={(e) => this.setState({product: {...product, description: e.target.value}})}/>

                <Input
                    type="textarea"
                    label={`${t("Description")} ${t("Secondary language")}`}
                    id="productDescriptionInputEn"
                    name="productDescriptionInputEn"
                    value={product.descriptionEn}
                    style={InputStyles.Material}
                    validations={[inputValidations.productDescriptionEnInput, showValidation]}
                    onChange={(e) => this.setState({product: {...product, descriptionEn: e.target.value}})}/>


                <ProductImages
                    images={product.images}
                    onChange={(images) => this.setState({images: images})}/>

                <Button
                    className="mt-24"
                    size={ButtonSizes.Full}
                    color={ButtonColors.Green}
                    type={ButtonTypes.Filled}
                    onClick={() => this.handleCreateProduct()}>
                    {t("Save")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        menus: state.app.menus,
        menus_fetched: state.app.menus_fetched,
        user_details: state.app.user_details,
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    createProduct: (data) => dispatch(createProduct(data)),
    addProductImage: (productId, image) => dispatch(addProductImage(productId, image)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTranslation()(CreateProductSidebar));

