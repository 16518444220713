import React, {Component} from "react";
import styles from "./OrdersSidebar.module.scss";
import {withTranslation} from "react-i18next";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../Button/Button";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as QrCodeIcon} from "../../../assets/icons/qr-code.svg";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import Card from "../../Card/Card";
import OrderCard from "../../Cards/OrderCard/OrderCard";
import {setLoading} from "../../../actions";
import {createTable, getTables} from "../../../actions/tables";
import {connect} from "react-redux";
import {askForConfirmation} from "../../../utils";
import {ORDER_STATUSES} from "../../../utils/constants";
import {updateOrderStatus} from "../../../actions/orders";
import Empty from "../../Empty/Empty";

class OrdersSidebar extends Component {
    componentDidMount() {
        //
    }

    approveOrder = async(order) => {
        const {t, updateOrderStatus} = this.props;
        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }
        this.props.setLoading(true);
        updateOrderStatus(order.id, ORDER_STATUSES.APPROVED).then(res => {
            this.props.setLoading(false);
            this.props.updateOrders();
        });
    }

    declineOrder = async(order) => {
        const {t, updateOrderStatus} = this.props;
        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }
        this.props.setLoading(true);
        updateOrderStatus(order.id, ORDER_STATUSES.DECLINED).then(res => {
            this.props.setLoading(false);
            this.props.updateOrders();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="d-flex justify-content-between mb-16">
                    <h2>{this.props.table.name}</h2>
                    <div className="d-flex">
                        <Button
                            className="mr-12"
                            type={ButtonTypes.Icon}
                            onClick={this.props.onEditButtonClick}>
                            <EditIcon/>
                        </Button>
                        <Button
                            className="mr-12"
                            type={ButtonTypes.Icon}
                            onClick={this.props.onQrCodeButtonClick}>
                            <QrCodeIcon/>
                        </Button>
                        <Button
                            type={ButtonTypes.Icon}
                            onClick={this.props.onCloseButtonClick}>
                            <CloseIcon/>
                        </Button>
                    </div>
                </div>
                {!!this.props.calls &&
                <Card>
                    <p className="mb-12">{t("You have")} {this.props.calls} {t("calls from this table")}</p>
                    <Button
                        type={ButtonTypes.Filled}
                        color={ButtonColors.Green}
                        size={ButtonSizes.Full}
                        onClick={() => this.props.clearTableCalls()}>
                        {t("Clear calls")}
                    </Button>
                </Card>}
                {!this.props.user_details?.configuration?.enableOrder &&
                <div>
                    <p className="mt-16 mb-12">{t("Pending orders")}</p>
                    {this.props.orders.length ?
                        this.props.orders.map(order =>
                            <OrderCard
                                order={order}
                                onDetailsButtonClick={() => this.props.onDetailsButtonClick(order)}
                                onApproveButtonClick={() => this.approveOrder(order)}
                                onDeclineButtonClick={() => this.declineOrder(order)}/> )
                        : <div className="mt-94 text-center">
                            <Empty />
                            {/*<p className="mt-16">{t("No orders to display!")}</p>*/}
                        </div>}
                </div>}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    user_details: state.app.user_details
});
const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    updateOrderStatus: (orderId, status) => dispatch(updateOrderStatus(orderId, status))
});
const connector = connect(null, mapDispatchToProps);
export default connector(withTranslation()(OrdersSidebar));
