import React, {Component} from "react";
import MenuCard from "../../../components/Cards/MenuCard/MenuCard";
import DailyMenuCard from "../../../components/Cards/DailyMenuCard/DailyMenuCard";
import BannerCard from "../../../components/Cards/Banner/Banner";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import PostCard from "../../../components/Cards/PostCard/PostCard";
import {BLANK_IMAGE, CLIENT_MODAL_TYPES} from "../../../utils/constants";
import {connect} from "react-redux";
import {openModal, setDailyMenu, setEvent} from "../../../actions/client";
import MenuImage from "../../../assets/images/menu.png";
import styles from "./Menu.module.scss";
import {scroller} from "react-scroll";
import {isTimeBetween} from "../../../utils";

class Menus extends Component {

    componentDidMount() {
        const local_submenu_id = localStorage.getItem("selected_submenu");
        if(local_submenu_id) {
            localStorage.removeItem("selected_submenu");
        }
    }

    openMenus = () => {
        const {history, match: { params } } = this.props;
        history.push(`/menu/${params.businessId}/${params.table}`);
    }

    handleDailyMenuClick = (daily_menu) => {
        const {openModal, setDailyMenu} = this.props;
        setDailyMenu(daily_menu);
        openModal(CLIENT_MODAL_TYPES.DAILY_MENU);
    }

    handleEventClick = (event) => {
        const {openModal, setEvent} = this.props;
        setEvent(event);
        openModal(CLIENT_MODAL_TYPES.EVENT);
    }

    handleBannerClick = (banner) => {
        window.open(banner.link, "_blank");
    }

    render() {
        const {banners, events, daily_menus, t, i18n} = this.props;
        const dailyMenusExist = daily_menus.some(daily_menu => isTimeBetween(daily_menu.startTime, daily_menu.endTime));
        const eventsExist = !!events.length;

        return (
            <div className={styles.menusContainer}>

                <MenuCard
                    defaultMenu
                    className="mb-12"
                    menu={{title: "Menu", photoUrl: MenuImage}}
                    onClick={this.openMenus}/>

                {dailyMenusExist &&
                <div className={styles.title}>
                    {t("Daily menus")}
                </div>}
                {/*{daily_menus.map((daily_menu, index) =>*/}
                {/*    <DailyMenuCard*/}
                {/*        className="mb-12"*/}
                {/*        daily_menu={daily_menu}*/}
                {/*        onClick={() => this.handleDailyMenuClick(daily_menu)}/>)}*/}

                {eventsExist &&
                <div className={styles.title}>
                    {t("Events")}
                </div>}
                {events.map((event, index) =>
                    <PostCard
                        className="mb-12"
                        post={event}
                        onClick={() => this.handleEventClick(event)}/>)}

                {banners.map((banner, index) =>
                    <BannerCard
                        banner={banner}
                        handleClick={() => this.handleBannerClick(banner)}/>)}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        daily_menus: state.client.daily_menus,
        events: state.client.events,
        banners: state.client.banners,
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: (type) => dispatch(openModal(type)),
    setEvent: event => dispatch(setEvent(event)),
    setDailyMenu: daily_menu => dispatch(setDailyMenu(daily_menu))

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menus)));
