import React from "react";
import styles from "./ProductCard.module.scss";
import {useTranslation, withTranslation} from "react-i18next";
import classNames from "classnames";
import {BLANK_IMAGE, getCurrencyLocally} from "../../../utils/constants";
import Button, {ButtonTypes} from "../../Button/Button";
import {ReactComponent as PlusIcon} from "../../../assets/icons/plus-1.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import {ReactComponent as HeartIcon} from "../../../assets/icons/favorite.svg";
import {
    addProductToWishlist,
    decreaseQuantityOfWishlistProduct, getNameByLanguage, getProductWishlistQuantity,
    increaseQuantityOfWishlistProduct,
    isAddedToWishlist as productIsAddedToWishlist, removeProductFromWishlist, truncate
} from "../../../utils";
import {getOrders, updateWishlist} from "../../../actions/client";
import {createOrder, sendOrder} from "../../../actions/orders";
import {connect, useSelector} from "react-redux";
import Quantity from "../../Quantity/Quantity";
import DefaultImage from "../../../assets/images/default-product-image.png";
import TextTruncate from 'react-text-truncate'

const ProductCard = ({product, cartProduct, addToWishlist, hidePrice, className, onClick, updateWishlist, is_ordering_enabled, is_delivery_enabled, gridView}) => {

    const {t, i18n} = useTranslation();
    const image = product.images?.[0]?.thumbnail || product.images?.[0]?.link;
    const isAddedToWishlist = productIsAddedToWishlist(product);
    const business = useSelector(state => state.client.business);

    const onPlusButtonClick = e => {
        e.stopPropagation();
        console.log(isAddedToWishlist)
        if (isAddedToWishlist) {
            removeProductFromWishlist(product);
        } else {
            addProductToWishlist(product);
        }
        updateWishlist();
    }

    const increaseProductQuantity = (e) => {
        e.stopPropagation();
        increaseQuantityOfWishlistProduct(product)
        updateWishlist();
    }

    const decreaseProductQuantity = (e) => {
        e.stopPropagation();
        decreaseQuantityOfWishlistProduct(product);
        updateWishlist();
    }

    return (

        <div className={classNames(styles.productCard, className, {[styles.grid]: gridView})}
             onClick={onClick}>
            <div className={styles.productCardImage}
                 style={{backgroundImage: "url('" + (image || DefaultImage) + "')"}}/>
            <div className={styles.productCardContent}>

                <p className={classNames(styles.productCardTitle, "product-title")}>
                    {gridView ?
                        <TextTruncate
                            line={2}
                            truncateText="…"
                            containerClassName="product-title"
                            text={getNameByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, product)}
                        />
                        : getNameByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, product)
                    }
                </p>
                {!hidePrice &&
                <p className={styles.productCardSubtitle}>{product.price}{getCurrencyLocally()}</p>}
            </div>

            {(cartProduct && (is_ordering_enabled || is_delivery_enabled)) &&
            <div className="ml-auto">
                <Quantity
                    small
                    inverse
                    quantity={getProductWishlistQuantity(product)}
                    onMinusButtonClick={decreaseProductQuantity}
                    onPlusButtonClick={increaseProductQuantity}/>
            </div>}

            {(addToWishlist && (is_ordering_enabled || is_delivery_enabled)) &&
            <Button
                type={ButtonTypes.Icon}
                onClick={onPlusButtonClick}
                className={classNames(styles.plusButton, {[styles.activePlusButton]: isAddedToWishlist})}>
                <PlusIcon/>
            </Button>}

            {((!is_ordering_enabled && !is_delivery_enabled) && (cartProduct || addToWishlist)) &&
            <Button
                type={ButtonTypes.Icon}
                onClick={onPlusButtonClick}
                className={classNames(styles.heartButton, {[styles.activeWishlistButton]: isAddedToWishlist})}>
                <HeartIcon/>
            </Button>}

            {(!cartProduct && !addToWishlist) &&
            <Button
                type={ButtonTypes.Icon}>
                <ArrowRightIcon/>
            </Button>}

        </div>
    )

}

const mapStateToProps = state => ({
    wishlist: state.client.wishlist,
    is_ordering_enabled: state.client.is_ordering_enabled,
    is_delivery_enabled: state.client.is_delivery_enabled
});
const mapDispatchToProps = dispatch => ({
    updateWishlist: () => dispatch(updateWishlist()),
});
const translation = withTranslation();
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
export default reduxConnector(translation(ProductCard));
