import React from "react";
import styles from "./TableQrCode.module.scss";
import {QRCode} from "react-qrcode-logo";
import SimpleLogo from "../../assets/images/menyja-logo-simple.png";
import MenyjaLogo from "../../assets/images/menyja-logo.svg";
import {useTranslation} from "react-i18next";
import NFCLogo from "../../assets/images/nfc.png";

const TableQrCode = React.forwardRef(({link, number, logo, color}, ref)=> {

    const { t, i18n } = useTranslation();
    console.log(i18n);

    return (
        <div ref={ref} className={styles.tableQrCode} id={number}>
            <QRCode
                bgColor="#151313"
                fgColor="#FDAF2A"
                eyeRadius={[
                    [5, 5, 0, 5], // top/left eye
                    [5, 5, 5, 0], // top/right eye
                    [5, 0, 5, 5], // bottom/left
                ]}
                qrStyle="dots"
                value={link || "https://www.menyja.co"}
            />

            {/*<div className={styles.tableQrCodeNumber}>{number || 1}</div>*/}
            {/*<div className={styles.tableQrCodeTextContent}>*/}
            {/*    <div className={styles.tableQrCodeTitle}>{t("Digital menu")}</div>*/}
            {/*    <div className={styles.tableQrCodeText}>{t("scan with camera")}</div>*/}
            {/*    {<div className={styles.tableQrCodeText}>{t("or visit menyja")}</div>}*/}
            {/*    /!*<div className={styles.tableQrCodeText}>{t("or tap in")}</div>*!/*/}
            {/*</div>*/}
            {/*<img src={MenyjaLogo} className={styles.tableQrCodeLogo} />*/}
            {/*<img src={NFCLogo} className={styles.tableQrCodeNfc} />*/}
        </div>
    )
})

export default TableQrCode;
