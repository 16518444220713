import React, {useEffect} from "react";
import {connect} from "react-redux";
import styles from "./LanguageModal.module.scss";
import {closePopup, openPopup} from "../../../actions/client";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import {ReactComponent as GlobeIcon} from "../../../assets/icons/language.svg";
import {ReactComponent as ArrowLeftIcon} from "../../../assets/icons/left.svg";
import {ReactComponent as AlbaniaIcon} from "../../../assets/icons/albania.svg";
import {ReactComponent as FranceIcon} from "../../../assets/icons/france.svg";
import {ReactComponent as UKIcon} from "../../../assets/icons/united-kingdom.svg";
import {CLIENT_MODAL_TYPES} from "../../../utils/constants";
import Checkbox from "../../Checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import {ReactComponent as NetherlandsIcon} from "../../../assets/icons/netherlands.svg";

const LanguageModal = ({openPopup, closePopup, business}) => {

    const {t, i18n} = useTranslation();

    useEffect(() => {
       console.log(i18n);
    });

    const primaryLanguage = business.configuration.language || "en";
    const secondaryLanguage = business.configuration.secondaryLanguage || "sq";

    const languageLabels = {
        en: "English",
        sq: "Shqip",
        fr: "Française",
        nl: "Nederlands",
    };

    const languageIcons = {
        en: <UKIcon className="mr-4"/>,
        sq: <AlbaniaIcon className="mr-4"/>,
        fr: <FranceIcon className="mr-4"/>,
        nl: <NetherlandsIcon className="mr-4"/>,
    };

    return (
        <div className={styles.languageModal}>
            <div className="d-flex justify-content-between">
                <div className="big-title">
                    <button
                        onClick={() => openPopup(CLIENT_MODAL_TYPES.SETTINGS)}
                        className="client-icon-button mr-3">
                        <ArrowLeftIcon/>
                    </button>
                    {t("Language")}
                </div>
                <button
                    onClick={closePopup}
                    className="client-icon-button">
                    <CloseIcon/>
                </button>
            </div>

            <div className="list-menu">
                <li onClick={() => {
                    i18n.changeLanguage(primaryLanguage);
                    localStorage.setItem("unem_language", primaryLanguage);
                    closePopup();
                }}>
                    <span>{languageIcons[primaryLanguage]} {languageLabels[primaryLanguage]}</span>
                    <Checkbox checked={i18n.language === primaryLanguage} />
                </li>
                <li onClick={() => {
                    i18n.changeLanguage(secondaryLanguage);
                    localStorage.setItem("unem_language", secondaryLanguage);
                    closePopup();
                }}>
                    <span>{languageIcons[secondaryLanguage]} {languageLabels[secondaryLanguage]}</span>
                    <Checkbox checked={i18n.language === secondaryLanguage} />
                </li>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    business: state.client.business,
});
const mapDispatchToProps = dispatch => ({
    openPopup: (type) => dispatch(openPopup(type)),
    closePopup: () => dispatch(closePopup())
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(LanguageModal);

