import React, {Component} from "react";
import "./Submenus.scss";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Modal from "../../../components/Modal/Modal";
import {generateSubMenuFromBusiness} from "../../../actions/user";
import {
    activateSubmenu,
    addSubmenuImage,
    createSubmenu,
    deactivateSubmenu,
    deleteSubmenu,
    getSubmenus,
    setSubmenus,
    updateSubmenu,
    updateSubmenuOrdering
} from "../../../actions/submenus";
import {
    askForConfirmation, correctTimes,
    editingTitle, getLanguageLabel,
    imageObjectToUrl,
    prepareOrderingPayload,
    reorder,
    search
} from "../../../utils";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import * as constants from "../../../utils/constants";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import Input, {InputStyles} from "../../../components/Input/Input";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Drawer from "../../../components/Drawer/Drawer";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import SelectIcons from "../../../components/SelectIcons/SelectIcons";
import moment from "moment";
import MenuPreview from "../../../components/Preview/MenuPreview";
import {getBusinessId} from "../../../utils/constants";

class Submenus extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            isMobile: (window.innerWidth < 768),
            showValidation: false,
            isModalOpen: false,
            editing: false,
            sidebar_opened: false,
            upload_submenu_sidebar_opened: false,
            imageUrls: [],
            menuUuid: null,
            creatingChefsChoice: true,
            menu_title: "",
            submenu: {
                title: "",
                titleEn: "",
                description: "",
                descriptionEn: "",
                category: "",
                logo: "",
                icon: "",
                startTime: moment("2020-10-10 00:00:00").format("HH:mm") + ":00",
                endTime: moment("2020-10-10 00:00:00").format("HH:mm") + ":00"
            },
            image: null
        }
    }

    imageInputRef = null;
    updateWindowDimensions = () => {
        this.setState({ isMobile: window.innerWidth < 768 });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        const menuId = this.props.match.params.menuId;
        if (this.props.menus) {
            const menu = this.props.menus.find(menu => menu.id == menuId);
            if (menu) {
                this.setState({menu_title: menu.title});
            }
        }
        this.props.getSubmenus(menuId);
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({
            showValidation: false,
            isModalOpen: false,
            editing: false,
            sidebar_opened: false,
            submenu: constants.EMPTY_SUBMENU_OBJECT,
            creatingChefsChoice: false
        });
    }

    clearSubmenu = () => {
        this.setState({ submenu: constants.EMPTY_SUBMENU_OBJECT });
    }

    openSubmenu = (submenu) => {
        const menuId = this.props.match.params.menuId;
        this.props.history.push("/menus/" + menuId + "/submenus/" + submenu.id + "/products");
    }

    onClickEdit = (item, e) => {
        e.stopPropagation();
        this.setState({submenu: item, editing: true, creatingChefsChoice: false});
        this.openModal();
    }

    onClickActivate = async(item, e) => {
        e.stopPropagation();
        const menuId = this.props.match.params.menuId;
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.activateSubmenu(menuId, item).then(res => {
            this.props.getSubmenus(menuId)
            this.props.setLoading(false);

        })
    }

    onClickDeactivate = async(item, e) => {
        console.log(item)
        e.stopPropagation();
        const menuId = this.props.match.params.menuId;
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deactivateSubmenu(menuId, item).then(res => {
            this.props.getSubmenus(menuId)
            this.props.setLoading(false);
        })
    }

    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const menuId = this.props.match.params.menuId;
        const submenuId = item.id;
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteSubmenu(menuId, submenuId, this.state.submenu).then(res => {
            this.props.getSubmenus(menuId);
            this.closeModal();
            this.props.setLoading(false);
        });
    }

    handleCreateSubmenu = async() => {
        const menuId = this.props.match.params.menuId;
        const {t} = this.props;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.createSubmenu(menuId, this.state.submenu).then(res => {
            const image = this.state.image;
            if (image) {
                this.props.addSubmenuImage(menuId, res.data.id, image).then(res => {
                    if(this.state.creatingChefsChoice) {
                        const submenus = [res.data, ...this.props.submenus];
                        const reorderedSubmenusPayload = prepareOrderingPayload(submenus, "subMenuId");
                        this.props.updateSubmenuOrdering(menuId, reorderedSubmenusPayload).then(res => {
                            this.props.getSubmenus(menuId);
                        });
                    } else {
                        this.props.getSubmenus(menuId);
                    }
                });
            } else {
                if(this.state.creatingChefsChoice) {
                    const submenus = [res.data, ...this.props.submenus];
                    const reorderedSubmenusPayload = prepareOrderingPayload(submenus, "subMenuId");
                    this.props.updateSubmenuOrdering(menuId, reorderedSubmenusPayload).then(res => {
                        this.props.getSubmenus(menuId);
                    });
                } else {
                    this.props.getSubmenus(menuId);
                }
            }
            this.closeModal();
            this.props.setLoading(false);
        });
    }

    handleUpdateSubmenu = async() => {
        const menuId = this.props.match.params.menuId;
        const submenuId = this.state.submenu.id;
        const {t} = this.props;

        if(this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        // if (!await askForConfirmation(t("Do you want to continue?"))) {
        //     return;
        // }

        const data = correctTimes(this.state.submenu);
        this.props.setLoading(true);
        this.props.updateSubmenu(menuId, submenuId, data).then(res => {
            const image = this.state.image;
            if (image) {
                this.props.addSubmenuImage(menuId, res.data.id, image).then(res => {
                    this.props.getSubmenus(menuId);
                });
            } else {
                this.props.getSubmenus(menuId);
            }
            this.closeModal();
            this.props.setLoading(false);
        });
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        console.log("result", result);

        const submenus = reorder(
            this.props.submenus,
            result.source.index,
            result.destination.index
        );

        const menuId = this.props.match.params.menuId;
        const reorderedSubmenusPayload = prepareOrderingPayload(submenus, "subMenuId");
        this.props.updateSubmenuOrdering(menuId, reorderedSubmenusPayload);

        this.props.setSubmenus(submenus);
    }

    formIsInvalid = () => {
        const {submenu} = this.state;
        return validate(inputValidations.submenuTitleInput, submenu.title) ||
            validate(inputValidations.submenuTitleEnInput, submenu.titleEn) ||
            validate(inputValidations.submenuDescriptionInput, submenu.description) ||
            validate(inputValidations.submenuDescriptionEnInput, submenu.descriptionEn);
    }

    openCreateSubmenuModal = () => {
        this.clearSubmenu();
        this.setState({
            sidebar_opened: true,
            editing: false,
            creatingChefsChoice: false
        });
    }

    openCreateChefsChoiceModal = () => {
        const {t} = this.props;
        this.clearSubmenu();
        this.setState({
            creatingChefsChoice: true,
            sidebar_opened: true,
            editing: false,
            submenu: {
                ...this.state.submenu,
                title: t("Chef's choice"),
                icon: "star"
            }
        });
    }

    openEditSubmenuModal = (submenu, e) => {
        e.stopPropagation();
        this.clearSubmenu();
        this.setState({
            submenu: submenu,
            sidebar_opened: true,
            editing: true,
            creatingChefsChoice: false
        });
    }

    // handleBusinessSubmenuGeneration = () => {
    //     if(this.state.imageUrls.length < 1){
    //         return;
    //     }
    //     this.props.setLoading(true);
    //     const menuUuid = localStorage.getItem("menuUuid") || crypto.randomUUID();
    //     const menuId = this.props.match.params.menuId;
    //
    //     const requestBody = {
    //         menuUuid: menuId,
    //         menuId: menuId,
    //         imageUrls: this.state.imageUrls
    //     };
    //     try {
    //         this.props.generateSubMenuFromBusiness(requestBody);
    //         // Delay the start of the status checks to give time for generateMenu to initiate
    //         setTimeout(() => {
    //             let hasTimedOut = false;
    //
    //             // Set a timeout for 1 minute (60000 milliseconds)
    //             const timeoutId = setTimeout(() => {
    //                 hasTimedOut = true;
    //                 clearInterval(checkInterval); // Clear the interval
    //                 this.props.setLoading(false);
    //                 alert("Time limit reached. SuMenu generation is taking longer than expected.");
    //             }, 60000);
    //
    //             // Step 2: Start a loop or interval to periodically check the status
    //             const checkInterval = setInterval(async () => {
    //                 if (hasTimedOut) {
    //                     return; // Stop the interval callback from running if it has timed out
    //                 }
    //
    //                 const checkResponse = await this.props.checkBusinessMenuCreation();
    //                 console.log("-----Response from checkSubMenuCreation: " + checkResponse.data);
    //
    //                 // Check if the response indicates that the menu creation is complete
    //                 if (checkResponse && checkResponse.data /* condition to determine if the menu is ready */) {
    //                     clearTimeout(timeoutId); // Clear the timeout if the condition is met
    //                     clearInterval(checkInterval); // Clear the interval
    //                     this.props.setLoading(false);
    //                     localStorage.removeItem("menuUuid");
    //                     this.props.addMenu(checkResponse.data);
    //                     window.location.reload();
    //                     //TODO: Add the menu to the menus list
    //                     // this.setState({
    //                     //     imageUrls: [], // Clear the images if needed
    //                     //     // Here, update the state with the new menu data
    //                     //     menus: [...this.state.menus, checkResponse.data]
    //                     // });
    //                 }
    //             }, 2000); // Adjust the interval time as needed
    //         }, 1000); // Delay the start of checking (e.g., 1000 milliseconds)
    //     } catch (error) {
    //         // Handle any errors
    //         console.error('Error in menu creation process:', error);
    //         alert("Error generating menu, try again!");
    //         localStorage.removeItem("menuUuid");
    //         this.props.setLoading(false);
    //     }
    // }

    render() {
        const {upload_submenu_sidebar_opened, isModalOpen, submenu, image, editing, menu_title, showValidation, sidebar_opened, search_text} = this.state;
        const {submenus_fetched, user_details, t} = this.props;
        const submenus = search(this.props.submenus, search_text, "title");
        return (
            <div className="submenus-page">
                <div className="submenus-layout">
                    {!this.state.isMobile &&<MenuPreview className="menu-preview" menuId={getBusinessId()}/>}

                    <div className="submenu-content">
                <div className="page-header-row">
                    <button
                        onClick={() => this.props.history.goBack()}
                        className="back-button"
                        aria-label="Go back to Menus">
                        {t("Menus")}/
                    </button>
                    <h1 className="submenu-text">{t("Submenus")}</h1>
                    <div className="d-flex">
                        {/*<Button*/}
                        {/*    className="mr-16"*/}
                        {/*    color={ButtonColors.Green}*/}
                        {/*    onClick={() => this.openCreateChefsChoiceModal()}>*/}
                        {/*    + {t("Create Chef's Choice")}*/}
                        {/*</Button>*/}
                        <Button
                            className="mr-16"
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateSubmenuModal()}>
                            + {t("Create Submenu")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for submenus")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>

                {submenus_fetched ?
                    submenus.length > 0 ?
                        <DragAndDrop
                            isSubmenu
                            items={submenus}
                            type={DraggableTypes.MENU}
                            onClickEdit={(item, e) => this.openEditSubmenuModal(item, e)}
                            onClickDelete={(item, e) => this.onClickDelete(item, e)}
                            onClickActivate={(item, e) => this.onClickActivate(item, e)}
                            onClickDeactivate={(item, e) => this.onClickDeactivate(item, e)}
                            onClickItem={(item, e) => this.openSubmenu(item, e)}
                            onDragEnd={(result) => this.onDragEnd(result)}/> : t("No submenus") + "..."
                    : <div className="text-center py-3">
                        <div className="spinner-border text-info"/>
                    </div>}

                <Drawer
                    open={sidebar_opened}>

                    <div className="d-flex justify-content-between mb-32">
                        <h2>{editing ? t("Edit Submenu") : t("Create Submenu")}</h2>
                        <div className="d-flex">
                            <Button
                                type={ButtonTypes.Icon}
                                onClick={this.closeModal}>
                                <CloseIcon/>
                            </Button>
                        </div>
                    </div>

                    <div className="mb-32">
                        <SelectIcons
                            selectedIcon={submenu.icon}
                            onChange={(icon) => this.setState({ submenu: { ...submenu, icon: icon } })}/>
                    </div>

                    <Input
                        label={`${t("Title")} (${t("Primary language")})`}
                        id="submenuTitleInput"
                        name="submenuTitleInput"
                        value={submenu.title}
                        style={InputStyles.Material}
                        validations={[inputValidations.submenuTitleInput, showValidation]}
                        onChange={(e) => this.setState({submenu: {...submenu, title: e.target.value}})}/>

                    <Input
                        label={`${t("Title")} (${t("Secondary language")})`}
                        id="submenuTitleInputEn"
                        name="submenuTitleInputEn"
                        value={submenu.titleEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.submenuTitleEnInput, showValidation]}
                        onChange={(e) => this.setState({submenu: {...submenu, titleEn: e.target.value}})}/>

                    <Input
                        type="textarea"
                        label={`${t("Description")} (${t("Primary language")})`}
                        id="submenuDescriptionInput"
                        name="submenuDescriptionInput"
                        value={submenu.description}
                        style={InputStyles.Material}
                        validations={[inputValidations.submenuDescriptionInput, showValidation]}
                        onChange={(e) => this.setState({submenu: {...submenu, description: e.target.value}})}/>

                    <Input
                        type="textarea"
                        label={`${t("Description")} (${t("Secondary language")})`}
                        id="submenuDescriptionInputEn"
                        name="submenuDescriptionInputEn"
                        value={submenu.descriptionEn}
                        style={InputStyles.Material}
                        validations={[inputValidations.submenuDescriptionEnInput, showValidation]}
                        onChange={(e) => this.setState({submenu: {...submenu, descriptionEn: e.target.value}})}/>

                    <div className="time-warning">
                        <h7><strong>Note:</strong> {t("Submenu Visibility")}</h7>
                    </div>
                    <Input
                        type="time"
                        label={t("Start time")}
                        id="submenuStartTimeInput"
                        name="submenuStartTimeInput"
                        value={submenu.startTime}
                        style={InputStyles.Material}
                        validations={[inputValidations.submenuStartTimeInput, showValidation]}
                        onChange={(time) => this.setState({submenu: {...submenu, startTime: time}})}/>

                    <Input
                        type="time"
                        label={t("End time")}
                        id="submenuTimeInput"
                        name="submenuEndTimeInput"
                        value={submenu.endTime}
                        style={InputStyles.Material}
                        validations={[inputValidations.submenuEndTimeInput, showValidation]}
                        onChange={(time) => this.setState({submenu: {...submenu, endTime: time}})}/>

                    <Button
                        className="mt-24"
                        size={ButtonSizes.Full}
                        color={ButtonColors.Green}
                        type={ButtonTypes.Filled}
                        onClick={() => editing ? this.handleUpdateSubmenu() : this.handleCreateSubmenu()}>
                        {editing ? t("Save") : t("Save")}
                    </Button>
                </Drawer>
                {/*<Drawer*/}
                {/*    open={upload_submenu_sidebar_opened}>*/}

                {/*    <div className="d-flex justify-content-between mb-32">*/}
                {/*        <h2>{t("Upload Menu")}</h2>*/}
                {/*        <div className="d-flex">*/}
                {/*            <Button*/}
                {/*                type={ButtonTypes.Icon}*/}
                {/*                onClick={this.closeModal}>*/}
                {/*                <CloseIcon/>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="upload-section"> /!* Apply the upload-section class here *!/*/}
                {/*        <div className="file-upload"> /!* Apply the file-upload class here *!/*/}
                {/*            <input type="file"*/}
                {/*                   id="file"*/}
                {/*                   key="file"*/}
                {/*                   onChange={(e) => this.handleImageUpload(e.target.files[0])}*/}
                {/*                   onInvalid={(e) => alert("Invalid file")}*/}
                {/*            />*/}
                {/*            <label htmlFor="file" className="btn-2 subscribe-button"> /!* Use the button style from subscribe-button class *!/*/}
                {/*                {t("Upload menu")}*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        {this.state.imageUrls.map((url, index) => (*/}
                {/*            <div key={index} className="image-container uploaded-image-preview"> /!* Use uploaded-image-preview for image container *!/*/}
                {/*                <button className="remove-button upload-control-button" onClick={() => this.handleRemoveImage(index)}> /!* Style the remove button *!/*/}
                {/*                    {t("Remove")}*/}
                {/*                </button>*/}
                {/*                <img src={url} alt={`Uploaded menu ${index}`} className="uploaded-image"/>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}



                {/*    /!*<Input*!/*/}
                {/*    /!*    label={t("Photo or Video")}*!/*/}
                {/*    /!*    type="file"*!/*/}
                {/*    /!*    id="menuImageInput"*!/*/}
                {/*    /!*    name="menuImageInput"*!/*/}
                {/*    /!*    value={file}*!/*/}
                {/*    /!*    style={InputStyles.Material}*!/*/}
                {/*    /!*    validations={[inputValidations.menuImageInput, showValidations]}*!/*/}
                {/*    /!*    onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>*!/*/}

                {/*    <Button*/}
                {/*        className="mt-24"*/}
                {/*        size={ButtonSizes.Full}*/}
                {/*        color={ButtonColors.Green}*/}
                {/*        type={ButtonTypes.Filled}*/}
                {/*        onClick={() => this.handleBusinessSubmenuGeneration() }>*/}
                {/*        {t("Generate")}*/}
                {/*    </Button>*/}
                {/*</Drawer>*/}
            </div>
            </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        menus: state.app.menus,
        submenus: state.app.submenus,
        submenus_fetched: state.app.submenus_fetched,
        user_details: state.app.user_details,
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    setSubmenus: data => dispatch(setSubmenus(data)),
    getSubmenus: menuId => dispatch(getSubmenus(menuId)),
    createSubmenu: (menuId, submenu) => dispatch(createSubmenu(menuId, submenu)),
    updateSubmenuOrdering: (menuId, data) => dispatch(updateSubmenuOrdering(menuId, data)),
    updateSubmenu: (menuId, submenuId, submenu) => dispatch(updateSubmenu(menuId, submenuId, submenu)),
    deleteSubmenu: (menuId, submenuId, submenu) => dispatch(deleteSubmenu(menuId, submenuId)),
    addSubmenuImage: (menuId, submenuId, data) => dispatch(addSubmenuImage(menuId, submenuId, data)),
    activateSubmenu: (menuId, data) => dispatch(activateSubmenu(menuId, data)),
    deactivateSubmenu: (menuId, data) => dispatch(deactivateSubmenu(menuId, data)),
    // generateSubMenuFromBusiness: (data) => dispatch(generateSubMenuFromBusiness(data)),
    // checkBusinessSubMenuCreation: (data) => dispatch(checkBusinessSubMenuCreation(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Submenus)));
