import React, {useEffect, useState} from "react";
import styles from "./Sidebar.module.scss";
import {Link, useHistory} from "react-router-dom";
import {logout} from "../../actions/user";
import {unregister} from "../../actions/notifications";
import {connect} from "react-redux";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {ReactComponent as DashboardIcon} from "../../assets/icons/dashboard.svg";
import {ReactComponent as OrdersIcon} from "../../assets/icons/order.svg";
import {ReactComponent as TablesIcon} from "../../assets/icons/table.svg";
import {ReactComponent as MenusIcon} from "../../assets/icons/menu.svg";
import {ReactComponent as DailyMenusIcon} from "../../assets/icons/time.svg";
import {ReactComponent as ProductsIcon} from "../../assets/icons/product.svg";
import {ReactComponent as PostsIcon} from "../../assets/icons/post.svg";
import {ReactComponent as ReviewsIcon} from "../../assets/icons/reviews.svg";
import {ReactComponent as HelpIcon} from "../../assets/icons/support.svg";
import {ReactComponent as SettingsIcon} from "../../assets/icons/gear.svg";
import {ReactComponent as CollapseIcon} from "../../assets/icons/collapse.svg";
import {ReactComponent as Logo} from "../../assets/icons/logo-text.svg";
import {ReactComponent as LogoIcon} from "../../assets/icons/logo-icon.svg";
import {ReactComponent as DeliveryIcon} from "../../assets/icons/delivery.svg";
import Button, {ButtonTypes} from "../Button/Button";
import {useLocation} from "react-router";


const Sidebar = ({ logout, unregister, user_details }) => {

    const isMobile = window.innerWidth < 768;

    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState(!isMobile);

    useEffect(() => {
        console.log(user_details);
    }, [user_details]);

    const toggleSidebar = () => {
        if(!open) {
            document.body.classList.remove("sidebar-closed");
        } else {
            document.body.classList.add("sidebar-closed");
        }
        setOpen(!open);
    }


    const handleClick = (e) => {
        e.preventDefault();
        const firebase_token = localStorage.getItem("firebase_token");
        unregister();
        logout()
        history.push("/");
    }

    const { t, i18n } = useTranslation();

    const groups = [
        {
            title: "Data",
            icon: 0,
            items: [
                {title: t("Menus"), icon: <MenusIcon/>, link: "/menus"},
                // {title: t("Daily menus"), icon: <DailyMenusIcon/>, link: "/daily"},
                {title: t("Products"), icon: <ProductsIcon/>, link: "/products"},
                {title: t("Orders"), icon: <OrdersIcon/>, link: "/orders", hidden: !user_details?.configuration?.enableOrder},
                {title: t("Delivery"), icon: <DeliveryIcon/>, link: "/delivery", hidden: !user_details?.configuration?.enableDelivery},
                {title: t("Tables"), icon: <TablesIcon/>, link: "/tables"},
                {title: t("Events"), icon: <PostsIcon/>, link: "/events"},
            ]
        },
        {
            title: "Management",
            icon: 0,
            items: [
                {title: t("Feedbacks"), icon: <ReviewsIcon/>, link: "/feedback"},
                {title: t("Help"), icon: <HelpIcon/>, link: "/help"},
                {title: t("Settings"), icon: <SettingsIcon/>, link: "/account"},
            ]
        },
    ]

    return (
        <aside className={classNames(styles.sidebar, { [styles.closed]: !open})}>
            <div className={styles.sidebarHeader}>
                {open || isMobile
                    ? <Logo className={styles.logo} onClick={toggleSidebar} />
                    : <LogoIcon className={styles.logo} onClick={toggleSidebar} />}
                <Button
                    type={ButtonTypes.Icon}
                    onClick={toggleSidebar}>
                    <CollapseIcon/>
                </Button>
            </div>
            <ul className={styles.sidebarMenu}>
                    {groups.map(group => (
                        <>
                            <li className={styles.sidebarMenuHeader}>{group.title}</li>
                            {group.items.map(item => {
                                const isActive = location.pathname.startsWith(item.link);
                                return !item.hidden && <li className={classNames(styles.sidebarMenuItem)}>
                                    <Link
                                        to={item.link}
                                        className={classNames(styles.sidebarMenuLink, {[styles.active]: isActive})}
                                        onClick={() => {
                                            if(isMobile) {
                                                setOpen(false);
                                            }
                                        }}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            })}
                        </>
                    ))}
                </ul>
        </aside>
    )
}

const mapStateToProps = state => ({
    user_details: state.app.user_details
})

const mapDispatchToProps = dispatch => ({
    logout: data => dispatch(logout(data)),
    unregister: data => dispatch(unregister(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
