import React from "react";
import styles from "./ProductRow.module.scss";
import {ReactComponent as DragIcon} from "../../../assets/icons/6-dots.svg";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/icons/trash.svg";
import {BLANK_IMAGE, getCurrencyLocally} from "../../../utils/constants";
import Button, {ButtonTypes} from "../../Button/Button";
import Stars from "../../Stars/Stars";
import {getProductStarTypes} from "../../../utils";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

const ProductRow = ({product, ratings, onRatingsClick, onEditClick, onDeleteClick}) => {
    const {t} = useTranslation();

    const image = product.images?.[0]?.link;

    const rating = ratings?.find(rating => rating.productId == product.id)?.averageRate.toFixed(1) || "0.0";
    return (
        <div className={styles.productRow}>
            <DragIcon className={styles.dragIcon} />
            <div className={styles.image}
                style={{backgroundImage: "url('" + (image || BLANK_IMAGE) + "')"}}/>
            <div className={styles.content}>
                <p className={styles.title}>{product.name}</p>
                <p className={styles.subtitle}>{product.price}{getCurrencyLocally()}</p>
            </div>
            <div className={styles.ratings}>
                <p className="mr-12">{rating}</p>
                {!!(ratings && ratings.length) &&
                <Stars
                    handleClick={onRatingsClick}
                    stars={getProductStarTypes(ratings, product.id)}/>}
            </div>
            <div className={styles.actions}>
                {onEditClick && <div data-tip={t("Edit")}>
                    <Button
                        type={ButtonTypes.Icon}
                        onClick={onEditClick}>
                        <EditIcon/>
                    </Button>
                </div>}
                <div data-tip={t("Delete")}>
                    <Button
                        icon={<DeleteIcon/>}
                        type={ButtonTypes.Icon}
                        onClick={onDeleteClick}>
                        <DeleteIcon/>
                    </Button>
                </div>
            </div>
            <ReactTooltip place="top" type="dark" effect="float"/>
        </div>
    )
}

export default ProductRow;
