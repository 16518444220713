import React, { useState, useEffect } from "react";
import "./Spinner.scss";

const Spinner = () => {
    const [loadingMessage, setLoadingMessage] = useState("Loading...");

    useEffect(() => {
        const messages = ["Loading...", "Please wait...", "Still working on it..."];
        let index = 0;

        const intervalId = setInterval(() => {
            setLoadingMessage(messages[index]);
            index = (index + 1) % messages.length;
        }, 1500); // Change message every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <div className="spinner-container">
            <div className="spinner"></div>
            <p className="loading-text">{loadingMessage}</p>
        </div>
    );
};

export default Spinner;
