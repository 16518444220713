// a little function to help us with reordering the result
import {
    DEVICE_TYPES,
    PRODUCT_SORTING_TYPES,
    RATING_LEVELS,
    RATING_PERCENTAGES_OBJECT,
    RATING_VALUES,
    STAR_TYPES
} from "./constants";
import {toast} from "react-toastify";
import firebase from "../firebaseInit";
import moment from "moment";
import confirm from "../components/ConfirmationDialog/Confirm";

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const prepareOrderingPayload = (items, property) => {
    return items.map((item, index) => {
        return {
            [property]: item.id,
            order: index
        }
    })
}

export const productExists = (products, id) => {
    var found = false;
    for(var i = 0; i < products.length; i++) {
        if (products[i].id == id) {
            found = true;
            break;
        }
    }
    return found;
}

export const getQueryParameter = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
}

export const imageObjectToUrl = (image) => {
    if(!image) return "";
    return URL.createObjectURL(image);
}

export const numberOfOccurrences = (arr, el, key) => {
    let numberOfOccurrences = 0;
    for(let i = 0; i < arr.length; i++) {
        if(arr[i][key] == el) {
            numberOfOccurrences++
        }
    }
    return numberOfOccurrences;
}

export const formatTime = (value) => {
    if(!value) return "00:00";
    if(value.includes("T")) {
        value = value.split("T")[1];
    }
    let items = value.split(":");
    return [items[0], items[1]].join(":");
}

const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes, seconds] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}:${seconds}`;
}

export function getMinutes(timeString){
    let [hh, mm, ss] = timeString.split(":");
    if(hh == "00"){
        hh = "24";
    }
    return parseInt(hh)*60 + parseInt(mm);
}

export function isTimeBetween(startTime,endTime){
    let checkTime = new Date().toLocaleTimeString();
    if(!startTime && !endTime) {
        return true;
    }
    if(!startTime) {
        startTime = "00:00:00";
    }
    if(!endTime) {
        endTime = "00:00:00";
    }
    if(startTime === "00:00:00" && endTime === "00:00:00") {
        return true;
    }
    if(startTime && endTime) {
        checkTime = convertTime12to24(checkTime);
        checkTime = getMinutes(checkTime);
        return (checkTime >= getMinutes(startTime) && checkTime <= getMinutes(endTime));
    } else {
        return true;
    }
}

export function isTimeBefore(checkTime, endTime){
    if(checkTime && endTime) {
        checkTime = getMinutes(checkTime);
        return (checkTime <= getMinutes(endTime));
    } else {
        return false;
    }
}

export const editingTitle = (value) => {
    return " \"" + value + "\"";
}

export const isMenusPage = () => (
    window.location.pathname === "/menu"
)

export const prepareCallWaiterData = (lat, lon, table_number) => {
    const data = {
        "latitude": lat,
        "longitude": lon,
        "tableNumber": parseInt(table_number)
    }
    return data;
}

export const getProductOnMenu = (menus, product) => {
    if(!menus || !product) return;
    for(let i = 0; i < menus.length; i++) {
        for(let j = 0; j < menus[i]["subMenus"].length; j++) {
            for(let k = 0; k < menus[i]["subMenus"][j]["products"].length; k++) {
                if(menus[i]["subMenus"][j]["products"][k]["id"] == product) {
                    const product = menus[i]["subMenus"][j]["products"][k];
                    return product;
                }
            }
        }
    }
}

export const getSubmenuOfProduct = (menus, product) => {
    if(!menus || !product) return;
    for(let i = 0; i < menus.length; i++) {
        for(let j = 0; j < menus[i]["subMenus"].length; j++) {
            for(let k = 0; k < menus[i]["subMenus"][j]["products"].length; k++) {
                if(menus[i]["subMenus"][j]["products"][k]["id"] == product) {
                    const submenu = menus[i]["subMenus"][j];
                    return submenu;
                }
            }
        }
    }
}

export const getAllProductsFromMenus = (menus) => {
    const products = [];
    for(let i = 0; i < menus.length; i++) {
        for(let j = 0; j < menus[i]["subMenus"].length; j++) {
            for(let k = 0; k < menus[i]["subMenus"][j]["products"].length; k++) {
                products.push(menus[i]["subMenus"][j]["products"][k])
            }
        }
    }
    return products;
}

export const getAllSubmenusFromMenus = (menus) => {
    const submenus = [];
    for(let i = 0; i < menus.length; i++) {
        for(let j = 0; j < menus[i]["subMenus"].length; j++) {
            submenus.push(menus[i]["subMenus"][j])
        }
    }
    return submenus;
}


export const getProductsFromMenu = (menu) => {
    const products = [];
    for(let i = 0; i < menu["subMenus"].length; i++) {
        for(let j = 0; j < menu["subMenus"][i]["products"].length; j++) {
            products.push(menu["subMenus"][i]["products"][j])
        }
    }
    return products;
}

export const getRatingByProductId = (ratings, product_id) => {
    const rating = ratings.find(rating => rating.product.id == product_id);
    if(rating) {
        return rating.rate;
    }
    return null;
}

export const getWishlistProducts = (products, daily_menus) => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist && products) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            wishlist = wishlist.map(item => {
                const product = products.find(product => product.id == item.id);
                const daily = daily_menus ? daily_menus.find(daily => daily.product.id == item.id) : null;
                if(!product && !daily) {
                    removeProductFromWishlist({ id: item.id || item });
                } else {
                    return product ? {...product, quantity: item.quantity || 0} : {...daily.product, quantity: item.quantity || 0}
                }
            })
        }
    }
    console.log("WISH", wishlist);
    return wishlist || [];
}

export const wishlistCount = () => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            return wishlist.length
        }
    }
    return 0;
}

export const addProductToWishlist = (product) => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array)
            var product_exists = wishlist.find(item => item.id == product.id);
            if(!product_exists) {
                wishlist.push({id: product.id, quantity: 1});
            }
    } else {
        wishlist = [{id: product.id, quantity: 1}];
    }
    wishlist = JSON.stringify(wishlist);
    localStorage.setItem("wishlist", wishlist);
}

export const removeProductFromWishlist = (product) => {
    let wishlist = localStorage.getItem("wishlist");
    console.trace("aaaaa")
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            let index = wishlist.findIndex(item => item.id == product.id);
            wishlist.splice(index, 1);
            wishlist = JSON.stringify(wishlist);
            localStorage.setItem("wishlist", wishlist);
        }
    }
}

export const increaseQuantityOfWishlistProduct = (product) => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            let index = wishlist.findIndex(item => item.id === product.id);
            if(index === -1) {
                addProductToWishlist(product);
                return;
            }
            wishlist[index] = {
                ...wishlist[index],
                quantity: wishlist[index].quantity + 1
            }

        }
    }  else {
        wishlist = [{id: product.id, quantity: 1}];
    }
    wishlist = JSON.stringify(wishlist);
    localStorage.setItem("wishlist", wishlist);
}

export const decreaseQuantityOfWishlistProduct = (product) => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            let index = wishlist.findIndex(item => item.id === product.id);
            if(index !== -1) {
                if(wishlist[index].quantity === 1) {
                    removeProductFromWishlist(product);
                    return;
                }
                wishlist[index] = {
                    ...wishlist[index],
                    quantity:  wishlist[index].quantity > 0 ? wishlist[index].quantity - 1 : 0
                }
                wishlist = JSON.stringify(wishlist);
                localStorage.setItem("wishlist", wishlist);
            }
        }
    }
}

export const getProductWishlistQuantity = (product) => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            const item = wishlist.find(item => item.id == product.id);
            return item?.quantity || 0;
        }
    }
    return 0;
}

export const clearWishlist = () => {
    localStorage.setItem("wishlist", JSON.stringify([]));
}

export const isAddedToWishlist = (product) => {
    let wishlist = localStorage.getItem("wishlist");
    if(wishlist) {
        wishlist = JSON.parse(wishlist);
        if(wishlist.constructor === Array) {
            return wishlist.find(item => item.id == product.id);
        }
    }
    return false;
}

export const copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export const search = (array, text, property) => {
    let list = [...array];
    if(text) {
        list = list.filter(item => {
            return item[property]?.toLowerCase().includes(text.toLowerCase());
        })
    }
    return list;
}

export const sort = (products, ratings, type) => {
    if(!type) return products;
    if(!products) return [];
    if(!products.length) return [];
    if(!ratings) return products;
    if(!ratings.length) return products;

    const products_rated = products.map(product => {
        const rating = ratings.find(rating => rating.productId === product.id);
        return {
            ...product,
            rating: rating ? rating.averageRate : 0
        }
    });

    let products_sorted = [ ...products_rated ];
    if(type === PRODUCT_SORTING_TYPES.RATING_LOWEST) {
        products_sorted = products_rated.sort((a, b) => a.rating - b.rating);
    }

    if(type === PRODUCT_SORTING_TYPES.RATING_HIGHEST) {
        products_sorted = products_rated.sort((a, b) => b.rating - a.rating);
    }

    return products_sorted;
}

export const setCookie = (name, value, duration) => {
    var expires = "";
    var date = new Date();
    date.setFullYear(date.getFullYear() + 50);
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const getProductStarTypesClient = (rating) => {
    const stars = [];
    for (let i = 1; i <= rating.rate; i++) {
        stars.push(STAR_TYPES.FULL)
    }
    return stars;
}

export const getProductStarTypes = (ratings, productId) => {
    let rating = ratings.find(rating => rating.productId == productId);
    const stars = [];

    if(!rating) {
        for (let i = 0; i < 5; i++) {
            stars.push(STAR_TYPES.EMPTY);
        }
        return stars;
    }

    let average = rating.averageRate;
    const remainder = average % 1;
    const full_stars = Math.floor(average);

    for (let i = 1; i <= 5; i++) {
        if(remainder) {
            if(i <= full_stars) {
                stars.push(STAR_TYPES.FULL)
            } else if(i == (full_stars + 1)) {
                stars.push(STAR_TYPES.HALF)
            } else {
                stars.push(STAR_TYPES.EMPTY)
            }
        } else {
            if(i <= full_stars) {
                stars.push(STAR_TYPES.FULL)
            } else {
                stars.push(STAR_TYPES.EMPTY)
            }
        }
    }
    return stars;
}

export const askForConfirmation = (text) => {
    return confirm(text);
}

export const successToast = (text, duration) => {
    toast.success(text || "Success!", {
        position: "top-right",
        autoClose: duration || 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const errorToast = (text, duration) => {
    toast.error(text || "Error!", {
        position: "top-right",
        autoClose: duration || 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const warningToast = (text, duration) => {
    toast.warning(text || "Warning!", {
        position: "top-right",
        autoClose: duration || 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return DEVICE_TYPES.WINDOWS
    }

    if (/android/i.test(userAgent)) {
        return DEVICE_TYPES.ANDROID
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return DEVICE_TYPES.IOS
    }

    return false;
}

export const notificationsAreEnabled = () => {
    if (!("Notification" in window)) {
        return false;
    }

    if (Notification.permission !== "granted") {
        return false;
    }

    if(!firebase.messaging.isSupported()) {
        return false;
    }

    return true;
}

export const prepareRatingPercentages = (data) => {
    // data = ratings from backend
    let percentages = { ...RATING_PERCENTAGES_OBJECT };
    if(!data) return percentages;
    if(!data.length) return percentages;

    Object.keys(RATING_LEVELS).forEach(level => {
        const value = RATING_VALUES[level];
        let ratings = data.filter(rating => rating.rate === value);
        console.log("RATINGS " + level, ratings);
        if(ratings.length) {
            const percentage = parseFloat((ratings.length / data.length) * 100).toFixed(1);
            const count = ratings.length;
            percentages = {
                ...percentages,
                [level]: { count, percentage }
            }
        }
    });

    return percentages;

}

export const parsePrice = (price) => {
    if(!price) return "";
    return parseFloat(price).toFixed(2);
}

export const isNotificationsPage = () => {
    return (window.location.pathname !== "/menu/submenus") &&
        (window.location.pathname !== "/menu/products") &&
        (window.location.pathname !== "/menu") &&
        (window.location.pathname !== "/tables") &&
        (window.location.pathname !== "/") &&
        (window.location.pathname !== "/login");
}

export const notificationsSupported = () => {
    return "Notification" in window;
}

export const existsInArray = (array, value, key) => {
    return array.find(item => {
        return key ? item[key] == value : item == value;
    })
}

export const setTableCalls = (data) => {
    const calls = [];
    for (let i = 0; i < data.length; i++)
        for (let j = 0; j < data[i].count; j++)
            calls.push({id: data[i].tableId, cookie: data[i].cookie});
    return calls;
}

export const lastTableCall = (calls, id) => {
    const filtered = calls.filter(call => call.id === id);
    if (filtered.length) {
        return filtered[filtered.length - 1];
    }
    return null;
}

export const prepareOrderProducts = (products) => {
    return products.map(product => ({
        "productId": product.id,
        "quantity": getProductWishlistQuantity(product)
    }));
}

export const flattenOrderItems = (items) => {
    if (!items) return [];
    const flattened = items.map(item => {
        return { ...item, ...item.product, id: item.id }
    });
    console.log(flattened);
    return flattened;
}

export const calculateTotal = (order) => {
    return order.orderItems.reduce((acc, val) => acc + (val.product.price * val.quantity) ,0).toFixed(2);
}

export const calculateTimeDifference = (date) => {
    const pastDate = moment(date);
    const currentDate = moment();

    const differenceInSeconds = currentDate.diff(pastDate, "seconds");

    if(differenceInSeconds < 60) {
        return "Disa sekonda me pare"
    } else if(differenceInSeconds < 3600) {
        return Math.round(differenceInSeconds / 60) + " minuta me heret"
    } else if(differenceInSeconds > 3600 && differenceInSeconds < 86400) {
        return Math.round(differenceInSeconds / 3600) + " ore me heret"
    } else {
        return Math.round(differenceInSeconds / 86400) + " dite me heret"
    }
    return differenceInSeconds;
}

export const correctTimes = (data) => {
    if(data.startTime && !data.endTime) {
        data.endTime = "00:00:00";
    } else if(!data.startTime && data.endTime) {
        data.startTime = "00:00:00";
    }
    if(data.startTime === "00:00:00" && data.endTime === "00:00:00") {
        data.startTime = null;
        data.endTime = null;
    }
    return data;
}

export const getLanguageLabel = (language) => {
    if(language === "sq" || !language) {
        return "(Shqip)";
    } else if(language === "fr") {
        return "(Française)";
    } else {
        return "(English)";
    }
}

export const getTitleByLanguage = (language, primaryLanguage, secondaryLanguage, entity) => {
    if(language === secondaryLanguage) {
        return entity.titleEn || entity.title;
    } else {
        return entity.title;
    }
}

export const getNameByLanguage = (language, primaryLanguage, secondaryLanguage, entity) => {
    if(language === secondaryLanguage) {
        return entity.nameEn || entity.name;
    } else {
        return entity.name;
    }
}

export const getDescriptionByLanguage = (language, primaryLanguage, secondaryLanguage, entity) => {
    if(language === secondaryLanguage) {
        return entity.descriptionEn || entity.description;
    } else {
        return entity.description;
    }
}

export function truncate(input) {
    if (input.length > 18) {
        return input.substring(0, 18) + '...';
    }
    return input;
};










