import React, {Component} from "react";
import ProductCard from "../../../Cards/Product/ProductCard";
import {withTranslation} from "react-i18next";
import {CLIENT_MODAL_PAGES, getCurrencyLocally} from "../../../../utils/constants";
import {getOrders, updateWishlist} from "../../../../actions/client";
import {connect} from "react-redux";
import {
    clearWishlist,
    decreaseQuantityOfWishlistProduct,
    getAllProductsFromMenus,
    getWishlistProducts,
    increaseQuantityOfWishlistProduct, prepareCallWaiterData,
    prepareOrderProducts,
    removeProductFromWishlist
} from "../../../../utils";
import {createOrder, sendOrder} from "../../../../actions/orders";
import styles from "./WishlistModalCart.module.scss"
import Input, {InputStyles} from "../../../Input/Input";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../Button/Button";
import Empty from "../../../Empty/Empty";
import {BLACK_LIST_ADDRESS, CALL_WAITER_INVALID_DISTANCE} from "../../../../utils/constants/backend_exceptions";

class WishlistModalCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: "",
            order: null,
            product: null,
            page: CLIENT_MODAL_PAGES.PRODUCTS_PAGE,
            loading: false
        }
    }

    handleIncreaseClick = (e, product) => {
        e.stopPropagation();
        increaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    handleDecreaseClick = (e, product) => {
        e.stopPropagation();
        decreaseQuantityOfWishlistProduct(product);
        this.props.updateWishlist();
    }

    handleRemoveFromWishlistButtonClick = (product) => {
        removeProductFromWishlist(product);
        this.props.updateWishlist();
    }

    sendOrder = () => {
        if(this.props.loading) {
            return;
        }

        const {comment} = this.state;
        const {menus, business_id, table_number, daily_menus, is_delivery_enabled, t} = this.props;

        const products = getAllProductsFromMenus(menus);
        const wishlist = getWishlistProducts(products, daily_menus);
        const order_products = prepareOrderProducts(wishlist);
        const createOrderPayload = {businessId: business_id, tableNumber: table_number, orderItems: order_products, comment: comment};

        if(is_delivery_enabled) {
            this.props.onGoToForm(createOrderPayload);
            return;
        }

        this.props.setLoading(true);
        navigator.geolocation.getCurrentPosition(position => {
                const sendOrderPayload = {latitude: position.coords.latitude, longitude: position.coords.longitude, businessId: business_id, comment: comment};
                this.props.onSendOrder(createOrderPayload, sendOrderPayload);
            },
            error => {
                console.log(error);
                this.props.setLoading(false);
                alert(t("location_denied_ios"))
            }
        );
    }

    render() {
        const {t, menus, daily_menus, is_delivery_enabled, loading} = this.props;
        const {comment} = this.state;
        const products = getAllProductsFromMenus(menus);
        const wishlist = getWishlistProducts(products, daily_menus);

        return (
            <div className={styles.wishlistModalCart}>

                {!!wishlist.length && <p className="big-title">
                    {wishlist.length} {t("products in the cart")}
                </p>}

                {!!wishlist.length && <p className="medium-title text-primary">
                    {wishlist?.reduce((acc, val) => acc + (val.price * val.quantity) ,0).toFixed(2) || "0"}{getCurrencyLocally()}
                </p>}

                {!!wishlist.length && <p className="font-17 mt-32 mb-12">
                    {t("Products")}
                </p>}

                {wishlist.length ?
                    wishlist.map(product =>
                    product &&
                    <ProductCard
                        cartProduct
                        className="mb-12"
                        product={product}/>)
                : <div className="mt-58 text-center">
                        <Empty />
                        <p className="font-20 mt-16">{t("No products to display!")}</p>
                        <p className="font-17 text-tertiary mt-2">{t("It looks like you haven't added anything to cart!")}</p>
                    </div>}

                {!!wishlist.length && <Input
                    type="textarea"
                    value={comment}
                    label={t("Comment")}
                    wrapperClassName="mt-48"
                    style={InputStyles.Material}
                    placeholder={t("Add a comment to your order") + "..."}
                    onChange={(e) => this.setState({comment: e.target.value})} />}


                {!!wishlist.length &&
                <Button
                    loading={loading}
                    size={ButtonSizes.Full}
                    type={ButtonTypes.Filled}
                    color={ButtonColors.Green}
                    onClick={this.sendOrder}
                    disabled={(!wishlist?.length ) || loading}>
                    {is_delivery_enabled ? t("Continue") : t("Complete order")}
                </Button>}
            </div>
        )
    }

}

const mapStateToProps = state => ({
    menus: state.client.menus,
    wishlist: state.client.wishlist,
    orders: state.client.orders,
    daily_menus: state.client.daily_menus,
    is_delivery_enabled: state.client.is_delivery_enabled
});
const mapDispatchToProps = dispatch => ({
    updateWishlist: () => dispatch(updateWishlist()),
    createOrder: (data) => dispatch(createOrder(data)),
    getOrders: (businessId) => dispatch(getOrders(businessId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
const translation = withTranslation();
export default connector(translation(WishlistModalCart));
