import React, {Component} from "react";
import "./Account.scss";
import {connect} from "react-redux";
import {
    createSession,
    getActiveSubscription,
    getBusinessDetails,
    getUserDetails,
    logout,
    manageSubscription,
    updateBusinessAddress,
    updateBusinessDetails
} from "../../../actions/user";
import {withRouter} from "react-router-dom";
import {ACCOUNT_PAGE_TABS, currencies} from "../../../utils/constants";
import {addLogo, changePassword, deleteLogo, updateBusinessConfigurations} from "../../../actions/account";
import {askForConfirmation, imageObjectToUrl, successToast} from "../../../utils";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import Input from "../../../components/Input/Input";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import {inputValidations, validationTests} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import moment from "moment";
import {ReactComponent as ProfileIcon} from "../../../assets/icons/profile-1.svg";
import {ReactComponent as SettingsIcon} from "../../../assets/icons/gear.svg";
import {ReactComponent as LockIcon} from "../../../assets/icons/password.svg";
import {ReactComponent as ArrowRightIcon} from "../../../assets/icons/right.svg";
import {ReactComponent as BillIcon} from "../../../assets/icons/bill.svg";
import {unregister} from "../../../actions/notifications";
import {ReactComponent as LogoutIcon} from "../../../assets/icons/sign-out.svg";
import {ReactComponent as CheckmarkIcon} from "../../../assets/icons/approve.svg"
import classNames from "classnames";
import * as constants from "../../../utils/constants";

const blank_image_url = "https://filestore.community.support.microsoft.com/api/images/72e3f188-79a1-465f-90ca-27262d769841";
class Account extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props;

        this.state = {
            showSubscriptionPopup: false,
            subscribed: false,
            yearlyPlan: false,
            monthlyPlan: false,
            cancelAtPeriodEnd: false,
            currentPeriodEnd: null,
            user: null,
            business: {
                id: "",
                name: "",
                phoneNumber: "",
                address: {
                    addressLine: "",
                    city: "",
                    country: "",
                    latitude: "",
                    longitude: ""
                },
                configuration: {
                    language: ""
                },
                startDayTime: moment("2020-10-10 00:00:00").format("HH:mm") + ":00",
                endDayTime: moment("2020-10-10 00:00:00").format("HH:mm") + ":00",
                logo: "",
                radiusInMeters: "",
                enableCallWaiter: true
            },
            logo: null,
            currentTab: ACCOUNT_PAGE_TABS.PROFILE,
            showValidations: false,
            showPopup: false,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            incorrectCurrentPassword: false
        }
    }

    logoInputRef = null;

    languageOptions = [
        {label: "Shqip", value: "sq"},
        {label: "Française", value: "fr"},
        {label: "English", value: "en"},
        {label: "Nederlands", value: "nl"},
    ];

    componentDidMount() {
        this.props.getBusinessDetails().then(res => {
            this.setState({business: res.data});
        })

        this.props.getUserDetails().then(res => {
            this.setState({user: res.data});
        })

        this.props.getActiveSubscription().then(res => {
            if (res.data != null && Object.keys(res.data).length > 0) {
                this.setState({subscribed: true});
                this.setState({cancelAtPeriodEnd: res.data.cancelAtPeriodEnd});
                this.setState({currentPeriodEnd: res.data.currentPeriodEnd});
                if(res.data.lookupKey === constants.MONTHLY) {
                    this.setState({ monthlyPlan: true, yearlyPlan: false });

                }
                if(res.data.lookupKey === constants.YEARLY){
                    this.setState({ yearlyPlan: true, monthlyPlan: false });
                }
            } else {
                this.setState({subscribed: false, yearlyPlan: false, monthlyPlan: false});
            }
            this.setState({subscription: res.data});
        })

        document.querySelector("section.content").classList.add("p-0")
    }

    componentWillUnmount() {
        document.querySelector("section.content").classList.remove("p-0")
    }

    handleSubmit = async () => {
        const {t} = this.props;

        if (this.formIsInvalid()) {
            this.setState({showValidations: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);

    }

    uploadLogo = async () => {
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.addLogo(this.state.logo).then(res => {
            this.props.getBusinessDetails();
            this.props.setLoading(false);
            successToast(t("Updated logo!"))
        })
    }


    deleteLogo = async () => {
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteLogo().then(res => {
            this.props.getBusinessDetails();
            this.props.setLoading(false);
            this.setState({logo: null, business: {...this.state.business, logo: ""}});
            successToast(t("Removed logo!"))
        })
    }

    formIsInvalid = () => {
        const {business} = this.state;
        return null;//Change this.
        return validate(inputValidations.accountNameInput, business.name) ||
            validate(inputValidations.accountAddressInput, business.address.addressLine) ||
            validate(inputValidations.accountPhoneInput, business.phoneNumber) ||
            validate(inputValidations.accountCityInput, business.address.city) ||
            validate(inputValidations.accountCountryInput, business.address.country);
    }

    logout = () => {
        this.props.unregister();
        this.props.logout()
        this.props.history.push("/");
    }

    debounce = null;

    onBusinessDetailsChange = (name, value) => {
        const {business} = this.state;

        this.setState({
            business: {...business, [name]: value}
        }, () => {
            if (!business.shortVersion && this.formIsInvalid()) {
                return;
            }
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.props.updateBusinessDetails(this.state.business, business.id)
                    .then(res => this.showSuccessPopup());
            }, 500);
        })

    }

    onBusinessAddressChange = (name, value) => {
        const {business} = this.state;
        this.setState({
            business: {...business, address: {...business.address, [name]: value}}
        }, () => {
            if (this.formIsInvalid()) {
                return;
            }
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.props.updateBusinessAddress(this.state.business.address, business.id)
                    .then(res => this.showSuccessPopup())
            }, 500);
        });
    }

    onBusinessConfigurationChange = (name, value) => {
        const {business} = this.state;
        this.setState({
            business: {...business, configuration: {...business.configuration, [name]: value}}
        }, () => {
            if (!business.shortVersion && this.formIsInvalid()) {
                return;
            }
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.props.updateBusinessConfigurations(this.state.business.configuration, business.id)
                    .then(res => this.showSuccessPopup())
            }, 500);
        });
    }

    showSuccessPopup = () => {
        this.setState({showPopup: true},
            () => {
                setTimeout(() => {
                    this.setState({showPopup: false});
                }, 1000)
            });
    }

    changePassword = () => {
        const {currentPassword, newPassword, confirmNewPassword} = this.state;
        if (!currentPassword || !newPassword || !confirmNewPassword || (newPassword !== confirmNewPassword)) {
            this.setState({showValidations: true});
            return;
        }

        const payload = {
            oldPassword: currentPassword,
            newPassword: newPassword
        }
        this.props.changePassword(payload)
            .then(res => {
                console.log(res);
                this.showSuccessPopup();
                this.setState({
                    currentTab: null,
                    incorrectCurrentPassword: !res.data
                });

                this.setState({
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                    showValidations: false,
                    currentTab: ACCOUNT_PAGE_TABS.PASSWORD
                })
            });
    }

    customNewPasswordValidationMessage = () => {
        const {t} = this.props;
        const {newPassword, confirmNewPassword} = this.state;
        if (newPassword && confirmNewPassword && (newPassword !== confirmNewPassword)) {
            return t("Passwords should match");
        }
        return null;
    }

    customCurrentPasswordValidationMessage = () => {
        const {t} = this.props;
        const {incorrectCurrentPassword} = this.state;
        if (incorrectCurrentPassword) {
            return t("Your current password is incorrect");
        }
        return null;
    }
    toggleSubscriptionPopup = () => {
        this.setState(prevState => ({showSubscriptionPopup: !prevState.showSubscriptionPopup}));
    };

    handleCancelSubscription = () => {
        // this.setState({yearlyPlan: false, monthlyPlan: false, subscribed: false})

        this.props.manageSubscription(this.state)
            .then(response => {
                // Assuming the URL is directly in the response body
                const redirectUrl = response.data; // Adjust this based on the actual response structure
                window.location.href = redirectUrl; // Redirect the user to the URL
            })
            .catch(error => {
                console.error("An error occurred", error);
                // Handle any errors here
            });
        // Handle subscription cancellation logic
        this.toggleSubscriptionPopup();
        // Additional logic after cancelling the subscription
    };

    handleMonthlySubscription = () => {
        console.log("Subscribed to monthly plan")
        this.setState({ key: constants.MONTHLY }, () => {
            // This code will run after the state has been updated
            this.props.createSession(this.state)
                .then(response => {
                    // Assuming the URL is directly in the response body
                    // Adjust this based on the actual response structure
                    window.location.href = response.data; // Redirect the user to the URL
                })
                .catch(error => {
                    console.error("An error occurred", error);
                    // Handle any errors here
                });
        });
    }

    handleYearlySubscription = () => {
        console.log("Subscribed to yearly plan")
        this.setState({ key: constants.YEARLY }, () => {
            // This code will run after the state has been updated
            this.props.createSession(this.state)
                .then(response => {
                    // Assuming the URL is directly in the response body
                    // Adjust this based on the actual response structure
                    window.location.href = response.data; // Redirect the user to the URL
                })
                .catch(error => {
                    console.error("An error occurred", error);
                    // Handle any errors here
                });
        });
    }

    renderExpiryBadge(yearlyPlan, monthlyPlan, currentPeriodEnd) {
        const {t} = this.props;

        const expirationDate= moment.unix(currentPeriodEnd); // Convert UNIX timestamp to moment
        const expirationText = t("Expires on")+` ${expirationDate.format('MMM DD, YYYY')}`; // Format the expiration date

        if(monthlyPlan){
            const isExpiringNextMonth = moment(expirationDate).isSame(moment().add(1, 'month'), 'month');
            if(isExpiringNextMonth) {
                return <div className="subscription-expiry-badge">{expirationText}</div>;
            }
            return null;
        }
        if(yearlyPlan) {
            const isExpiringNextYear = moment(expirationDate).isSame(moment().add(1, 'year'), 'year');
            if(isExpiringNextYear) {
                return <div className="subscription-expiry-badge">{expirationText}</div>;
            }
        }
        return null;
    }


    handleSwitchSubscription = () => {
        // Handle subscription switch logic
        if (this.state.yearlyPlan) {
            this.handleYearlySubscription();
        }
        if (this.state.monthlyPlan) {
            this.handleMonthlySubscription();
        }
        this.toggleSubscriptionPopup();
        // Additional logic for switching the subscription
    };

    render() {
        const {
            business,
            logo,
            showSubscriptionPopup,
            showValidations,
            subscribed,
            monthlyPlan,
            yearlyPlan,
            currentTab,
            showPopup,
            currentPassword,
            newPassword,
            confirmNewPassword,
            cancelAtPeriodEnd,
            currentPeriodEnd,
            user
        } = this.state;
        const {t} = this.props;

        return (
            <div className="account-page">
                {showSubscriptionPopup && (
                    <div className="subscription-overlay">
                        <div className="subscription-popup">
                            <button className="close-button" onClick={this.toggleSubscriptionPopup}>&times;</button>
                            <div className="subscription-popup-header">{t("Manage Your Subscription")}</div>
                            <div className="subscription-popup-content">
                                <p>{t("Are you sure you want to change your subscription plan?")}</p>
                            </div>
                            <div className="subscription-popup-actions">
                                {/*{yearlyPlan && (*/}
                                {/*    <button className="subscription-action-button switch-subscription-button"*/}
                                {/*            onClick={this.handleSwitchSubscription}>Switch to Monthly</button>*/}
                                {/*)}*/}
                                {/*{monthlyPlan && (*/}
                                {/*    <button className="subscription-action-button switch-subscription-button"*/}
                                {/*            onClick={this.handleSwitchSubscription}>Switch to Yearly</button>*/}
                                {/*)}*/}
                                <button className="subscription-action-button cancel-subscription-button"
                                        onClick={this.handleCancelSubscription}>{t("Manage")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/*TODO:Uncoment this for subscription*/}

                <div className={classNames("account-page-popup", {"active": showPopup})}>
                    <CheckmarkIcon/> {t("Changes were saved successfully")}
                </div>


                <div className="account-page-sidebar">
                    <h1>Settings</h1>

                    <ul className="list-menu">
                        <li onClick={() => this.setState({currentTab: ACCOUNT_PAGE_TABS.SUBSCRIPTION})}>
                            <span><BillIcon className="mr-12"/>{t("Subscription")}</span>
                            <ArrowRightIcon/>
                        </li>
                        <li onClick={() => this.setState({currentTab: ACCOUNT_PAGE_TABS.PROFILE})}>
                            <span><ProfileIcon className="mr-12"/>{t("Profile")}</span>
                            <ArrowRightIcon/>
                        </li>
                        {/*TODO:Uncoment this for subscription*/}
                        <li onClick={() => this.setState({currentTab: ACCOUNT_PAGE_TABS.CONFIGURATIONS})}>
                            <span><SettingsIcon className="mr-12"/>{t("Configurations")}</span>
                            <ArrowRightIcon/>
                        </li>
                        <li onClick={() => this.setState({currentTab: ACCOUNT_PAGE_TABS.PASSWORD})}>
                            <span><LockIcon className="mr-12"/>{t("Password")}</span>
                            <ArrowRightIcon/>
                        </li>
                    </ul>


                    <Button
                        onClick={this.logout}
                        type={ButtonTypes.Filled}
                        size={ButtonSizes.Full}
                        color={ButtonColors.Gray}
                        className="logout-button">
                        <LogoutIcon className="mr-12"/> {t("Log out")}
                    </Button>

                </div>
                <div className="account-page-content">
                    {currentTab === ACCOUNT_PAGE_TABS.SUBSCRIPTION && !subscribed &&
                        <div className="subscription-container">
                            <div className="subscription-option">
                                <div className="subscription-details">
                                    <div className="subscription-title">{t("Yearly Plan")}</div>
                                    <div className="subscription-price">{t("190.00")}{t("$")}</div>
                                    <div className="subscription-description">{t("Get two months free with our annual subscription plan.")}
                                    </div>
                                    <button className="subscribe-button"
                                            onClick={this.handleYearlySubscription}>{t("Subscribe")}
                                    </button>
                                </div>
                            </div>
                            <div className="subscription-option">
                                <div className="subscription-details">
                                    <div className="subscription-title">{t("Monthly Plan")}</div>
                                    <div className="subscription-price">{t("19.00")}{t("$")}</div>
                                    <div className="subscription-description">{t("Enjoy full access to our features on a monthly basis.")}
                                    </div>
                                    <button className="subscribe-button"
                                            onClick={this.handleMonthlySubscription}>{t("Subscribe")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {currentTab === ACCOUNT_PAGE_TABS.SUBSCRIPTION && subscribed && yearlyPlan && !monthlyPlan &&
                        <div className="subscription-container">
                            <div className="subscription-option active">
                                {cancelAtPeriodEnd&&this.renderExpiryBadge(yearlyPlan, monthlyPlan, currentPeriodEnd)}
                                {!cancelAtPeriodEnd&& <div className="subscription-badge">{t("Subscribed")}</div>}
                                <div className="subscription-details">
                                    <div className="subscription-title">{t("Yearly Plan")}</div>
                                    <div className="subscription-price">{t("190.00")}{t("$")}</div>
                                    <div className="subscription-description">{t("You're currently enjoying all features with the annual plan.")}
                                    </div>
                                    <button className="subscribe-button manage-subscription-button"
                                            onClick={this.toggleSubscriptionPopup}>{t("Manage Plan")}
                                    </button>
                                </div>
                            </div>
                            <div className="subscription-option">
                                <div className="subscription-details">
                                    <div className="subscription-title">{t("Monthly Plan")}</div>
                                    <div className="subscription-price">{t("19.00")}{t("$")}</div>
                                    <div className="subscription-description">{t("Switch to a monthly plan at any time.")}
                                    </div>
                                    <button className="subscribe-button dimmed">{t("Subscribe")}</button>
                                </div>
                            </div>
                        </div>
                    }

                    {currentTab === ACCOUNT_PAGE_TABS.SUBSCRIPTION && subscribed && monthlyPlan && !yearlyPlan &&
                        <div className="subscription-container">
                            <div className="subscription-option ">
                                <div className="subscription-details">
                                    <div className="subscription-title">{t("Yearly Plan")}</div>
                                    <div className="subscription-price">{t("190.00")}{t("$")}</div>
                                    <div className="subscription-description">{t("Get two months free with our annual subscription plan.")}
                                    </div>
                                    <button className="subscribe-button dimmed">{t("Subscribe")}</button>
                                </div>
                            </div>
                            <div className="subscription-option active">

                                {cancelAtPeriodEnd&&this.renderExpiryBadge(yearlyPlan, monthlyPlan, currentPeriodEnd)}
                                {!cancelAtPeriodEnd&& <div className="subscription-badge">{t("Subscribed")}</div>}
                                <div className="subscription-details">
                                    <div className="subscription-title">{t("Monthly Plan")}</div>
                                    <div className="subscription-price">{t("19.00")}{t("$")}</div>
                                    <div className="subscription-description">{t("You're currently enjoying all features with the monthly plan.")}
                                    </div>
                                    <button className="subscribe-button manage-subscription-button"
                                            onClick={this.toggleSubscriptionPopup}>{t("Manage Plan")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {currentTab === ACCOUNT_PAGE_TABS.PROFILE && <div className="profile-tab">


                        <div className="row mb-32">
                            <div className="col-12">
                                <div className="business-logo"
                                     style={{backgroundImage: "url(" + (business.logo || blank_image_url) + ")"}}/>
                                <input className="d-none"
                                       type="file"
                                       ref={ref => this.logoInputRef = ref}
                                       onChange={e => {
                                           const file = e.target.files?.[0];
                                           if (file) {
                                               this.setState({
                                                   logo: file,
                                                   business: {...business, logo: imageObjectToUrl(file)}
                                               }, () => {
                                                   this.uploadLogo();
                                               });
                                           }
                                       }}/>

                                <div className="row mt-5">
                                    <div className="col-6">
                                        <Button
                                            className="float-right"
                                            color={ButtonColors.Green}
                                            type={ButtonTypes.Filled}
                                            size={ButtonSizes.Small}
                                            onClick={() => this.logoInputRef.click()}>
                                            {t("Add logo")}
                                        </Button>
                                    </div>

                                    {(logo || business.logo) &&
                                        <div className="col-6">
                                            <Button
                                                className="float-left"
                                                color={ButtonColors.Red}
                                                type={ButtonTypes.Filled}
                                                size={ButtonSizes.Small}
                                                onClick={e => this.deleteLogo()}>
                                                {t("Remove logo")}
                                            </Button>
                                        </div>}
                                </div>
                            </div>

                        </div>

                        <div className="row mb-24">
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("Name")}
                                    id="accountNameInput"
                                    name="accountNameInput"
                                    value={business.name}
                                    validations={[inputValidations.accountNameInput, showValidations]}
                                    onChange={(e) => this.onBusinessDetailsChange("name", e.target.value)}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("Address")}
                                    id="accountAddressInput"
                                    name="accountAddressInput"
                                    value={business.address.addressLine}
                                    validations={[inputValidations.accountAddressInput, showValidations]}
                                    onChange={e => this.onBusinessAddressChange("addressLine", e.target.value)}/>
                            </div>

                        </div>
                        <div className="row mb-24">
                            <div className="col-12 col-md-6">
                                <Input
                                    disabled
                                    label={t("Email")}
                                    id="accountEmailInput"
                                    name="accountEmailInput"
                                    value={user?.email}
                                    // validations={[inputValidations.accountPhoneInput, showValidations]}
                                    onChange={e => null}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("Phone")}
                                    id="accountPhoneInput"
                                    name="accountPhoneInput"
                                    value={business.phoneNumber}
                                    validations={[inputValidations.accountPhoneInput, showValidations]}
                                    onChange={e => this.onBusinessDetailsChange("phoneNumber", e.target.value)}/>
                            </div>
                        </div>
                        <div className="row mb-24">
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("Country")}
                                    id="accountCountryInput"
                                    name="accountCountryInput"
                                    value={business.address.country}
                                    validations={[inputValidations.accountCountryInput, showValidations]}
                                    onChange={e => this.onBusinessAddressChange("country", e.target.value)}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("City")}
                                    id="accountCityInput"
                                    name="accountCityInput"
                                    value={business.address.city}
                                    validations={[inputValidations.accountCityInput, showValidations]}
                                    onChange={e => this.onBusinessAddressChange("city", e.target.value)}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Input
                                    type="textarea"
                                    label={`${t("Description")} (${t("Primary language")})`}
                                    id="accountDescriptionInput"
                                    name="accountDescriptionInput"
                                    value={business.description}
                                    validations={[inputValidations.accountDescription, showValidations]}
                                    onChange={e => this.onBusinessDetailsChange("description", e.target.value)}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    type="textarea"
                                    label={`${t("Description")} (${t("Secondary language")})`}
                                    id="accountDescriptionEnInput"
                                    name="accountDescriptionEnInput"
                                    value={business.descriptionEn}
                                    validations={[inputValidations.accountDescriptionEn, showValidations]}
                                    onChange={e => this.onBusinessDetailsChange("descriptionEn", e.target.value)}/>
                            </div>
                        </div>
                    </div>}

                    {currentTab === ACCOUNT_PAGE_TABS.CONFIGURATIONS && <div className="configurations-tab">
                        <p className="mb-16"><b>{t("Working hours")}</b></p>
                        <div className="row mb-24">
                            <div className="col-12 col-md-6">
                                <Input
                                    type="time"
                                    label={t("Start day time")}
                                    id="accountStartDayTimeInput"
                                    name="accountStartDayTimeInput"
                                    value={business.startDayTime}
                                    validations={[inputValidations.accountStartDayTime, showValidations]}
                                    onChange={time => this.onBusinessDetailsChange("startDayTime", time)}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    type="time"
                                    label={t("End day time")}
                                    id="accountEndDayTimeInput"
                                    name="accountEndDayTimeInput"
                                    value={business.endDayTime}
                                    validations={[inputValidations.accountEndDayTime, showValidations]}
                                    onChange={time => this.onBusinessDetailsChange("endDayTime", time)}/>
                            </div>
                        </div>

                        <p className="mb-16"><b>{t("Location")}</b></p>
                        <div className="row mb-24">
                            <div className="col">
                                <Input
                                    label={t("Latitude")}
                                    id="accountLatitudeInput"
                                    name="accountLatitudeInput"
                                    value={business.address.latitude}
                                    validations={[inputValidations.accountLatitudeInput, showValidations]}
                                    onChange={e => this.onBusinessAddressChange("latitude", e.target.value)}/>
                            </div>
                            <div className="col">
                                <Input
                                    label={t("Longitude")}
                                    id="accountLongitudeInput"
                                    name="accountLongitudeInput"
                                    value={business.address.longitude}
                                    validations={[inputValidations.accountLongitudeInput, showValidations]}
                                    onChange={e => this.onBusinessAddressChange("longitude", e.target.value)}/>
                            </div>
                        </div>

                        <p className="mb-16"><b>{t("Call waiter")}</b></p>
                        <div className="row">
                            <div className="col">
                                <Input
                                    type="switch"
                                    label={t("Enable call waiter")}
                                    id="accountWaiterInput"
                                    name="accountWaiterInput"
                                    value={business.enableCallWaiter}
                                    onChange={(value) => this.onBusinessDetailsChange("enableCallWaiter", value)}/>
                            </div>
                            <div className="col">
                                <Input
                                    label={t("Call waiter distance")}
                                    id="accountDistanceInput"
                                    name="accountDistanceInput"
                                    value={business.radiusInMeters}
                                    validations={[inputValidations.accountDistanceInput, showValidations]}
                                    onChange={(e) => this.onBusinessDetailsChange("radiusInMeters", e.target.value)}/>
                            </div>
                        </div>
                        <p className="mb-16 mt-16"><b>{t("Language")}</b></p>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("Primary language")}
                                    type="select"
                                    id="accountLanguageInput"
                                    name="accountLanguageInput"
                                    value={business.configuration.language}
                                    options={this.languageOptions}
                                    onChange={(e) => this.onBusinessConfigurationChange("language", e.target.value)}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("Secondary language")}
                                    type="select"
                                    id="accountSecondaryLanguageInput"
                                    name="accountSecondaryLanguageInput"
                                    value={business.configuration.secondaryLanguage}
                                    options={this.languageOptions}
                                    onChange={(e) => this.onBusinessConfigurationChange("secondaryLanguage", e.target.value)}/>
                            </div>
                        </div>
                        <p className="mb-16 mt-16"><b>{t("Currency")}</b></p>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("CurrencyDescription")}
                                    type="select"
                                    id="accountCurrencyInput"
                                    name="accountCurrencyInput"
                                    value={business.configuration.currency}
                                    options={currencies}
                                    onChange={(e) => this.onBusinessConfigurationChange("currency", e.target.value)}/>
                            </div>
                        </div>
                    </div>}

                    {currentTab === ACCOUNT_PAGE_TABS.PASSWORD && <div className="reset-password-tab">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Input
                                    type="password"
                                    label={t("Current password")}
                                    id="accountCurrentPasswordInput"
                                    name="accountCurrentPasswordInput"
                                    value={currentPassword}
                                    validations={[[validationTests.isNotEmpty], showValidations]}
                                    customValidationMessage={this.customCurrentPasswordValidationMessage()}
                                    onChange={(e) => this.setState({
                                        currentPassword: e.target.value,
                                        incorrectCurrentPassword: false
                                    })}/>
                            </div>
                            <div className="col-12 col-md-6"/>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-md-6">
                                <Input
                                    type="password"
                                    label={t("New password")}
                                    id="accountNewPasswordInput"
                                    name="accountNewPasswordInput"
                                    value={newPassword}
                                    validations={[[validationTests.isNotEmpty], showValidations]}
                                    customValidationMessage={this.customNewPasswordValidationMessage()}
                                    onChange={(e) => this.setState({newPassword: e.target.value})}/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    type="password"
                                    label={t("Re-enter new password")}
                                    id="accountConfirmNewPasswordInput"
                                    name="accountConfirmNewPasswordInput"
                                    value={confirmNewPassword}
                                    validations={[[validationTests.isNotEmpty], showValidations]}
                                    customValidationMessage={this.customNewPasswordValidationMessage()}
                                    onChange={(e) => this.setState({confirmNewPassword: e.target.value})}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Button
                                    className="mt-5"
                                    onClick={this.changePassword}
                                    type={ButtonTypes.Filled}
                                    size={ButtonSizes.Full}
                                    color={ButtonColors.Green}>
                                    {t("Submit")}
                                </Button>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        user_details: state.app.user_details,
        user_account_details: state.app.user_account_details,
    }
}

const mapDispatchToProps = dispatch => (
    {
        setLoading: data => dispatch(setLoading(data)),
        addLogo: data => dispatch(addLogo(data)),
        deleteLogo: data => dispatch(deleteLogo(data)),
        getUserDetails: data => dispatch(getUserDetails(data)),
        getActiveSubscription: data => dispatch(getActiveSubscription(data)),
        createSession: data => dispatch(createSession(data)),
        manageSubscription: data => dispatch(manageSubscription(data)),
        getBusinessDetails: data => dispatch(getBusinessDetails(data)),
        updateBusinessDetails: (data, id) => dispatch(updateBusinessDetails(data, id)),
        updateBusinessAddress: (data, id) => dispatch(updateBusinessAddress(data, id)),
        updateBusinessConfigurations: (data, id) => dispatch(updateBusinessConfigurations(data, id)),
        logout: data => dispatch(logout(data)),
        unregister: data => dispatch(unregister(data)),
        changePassword: data => dispatch(changePassword(data)),
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Account)));
