import React, {useEffect, useState} from 'react';
import './App.scss';
import {Redirect, Route, Switch, withRouter,} from "react-router-dom";
import {matchPath} from "react-router";
import {connect} from "react-redux";
import Tables from "./containers/Admin/Tables/Tables";
import Login from "./containers/Admin/Login/Login";
import Menus from "./containers/Admin/Menus/Menus";
import Products from "./containers/Admin/Products/Products";
import Submenus from "./containers/Admin/Submenus/Submenus";
import Submenu from "./containers/Admin/Submenu/Submenu";
import Sidebar from "./components/Sidebar/Sidebar";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {getBusinessDetails, getUserDetails} from "./actions/user";
import Account from "./containers/Admin/Account/Account";
import Waiter from "./containers/Admin/Waiter/Waiter";
import firebase from "./firebaseInit";
import {toast} from "react-toastify";
import Password from "./containers/Admin/Password/Password";
import ReactGA from 'react-ga4';
import Daily from "./containers/Admin/Daily/Daily";
import Dailies from "./containers/Admin/Dailies/Dailies";
// import 'antd/dist/antd';
import Events from "./containers/Admin/Events/Events";
import Statistics from "./containers/Admin/Statistics/Statistics";
import Client from "./containers/Client";
import {useTranslation} from "react-i18next";
import Spinner from "./components/Spinner/Spinner";
import classnames from "classnames";
import Landing from "./containers/Landing/Landing";
import Feedback from "./containers/Admin/Feedback/Feedback";
import {errorToast, isNotificationsPage, notificationsSupported, successToast, warningToast} from "./utils";
import Help from "./containers/Admin/Help/Help";
import Orders from "./containers/Admin/Orders/Orders";
import Search from "./containers/Client/Search/Search";
import DeliveryOrders from "./containers/Admin/DeliveryOrders/DeliveryOrders";
import TableQrCode from "./components/TableQrCode/TableQrCode";
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import Register from "./containers/Admin/Register/Register";
import {Dropdown, Menu} from "antd";
import { useLocation } from 'react-router-dom';
import {ReactComponent as UKIcon} from "./assets/icons/united-kingdom.svg";
import {ReactComponent as AlbaniaIcon} from "./assets/icons/albania.svg";
import {ReactComponent as FranceIcon} from "./assets/icons/france.svg";
import {ReactComponent as NetherlandsIcon} from "./assets/icons/netherlands.svg";
import i18n from "i18next";


const languageIcons = {
    en: <UKIcon className="mr-3"/>,
    sq: <AlbaniaIcon className="mr-3"/>,
    fr: <FranceIcon className="mr-3"/>,
    nl: <NetherlandsIcon className="mr-3"/>,
};




function App({user_details, access_token, firebase_token, location, history, getBusinessDetails, getUserDetails, user_details_fetched, loading}) {

    const {t, i18n} = useTranslation();


    useEffect(() => {
        const trackingId = "G-XM1FQX171F";
        ReactGA.initialize(trackingId);
        history.listen(location => {
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname + history.location.search,
                title: "Page visit"
            });
            // ReactGA.set({page: location.pathname}); // Update the user's current page
            // ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });
    });

    useEffect(() => {
        if (!user_details && !user_details_fetched && isAuthenticated()) {
            getBusinessDetails();
        }
        if(isNotificationsPage() && notificationsSupported()) {
            if (Notification.permission === "granted" && !matchClient()) {
                addFirebaseNotificationsListener();
            } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function (permission) {
                    if (permission === "granted" && !matchClient()) {
                        addFirebaseNotificationsListener();
                    } else {

                    }
                });
            }
        }
    }, []);

    const toast_content = (text) => (
        <span onClick={() => history.push("/")}>{t("Call from table") + ": " + text}</span>
    )

    const addFirebaseNotificationsListener = () => {
        if(firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            messaging.onMessage((message) => {
                console.log(message)
                if (message.data.title === "New Order") {
                    successToast(t("Order from table") + ": " + message.data.tableNumber);
                } else if(message.data.title === "New Delivery Order") {
                    warningToast(t("New delivery order"));
                } else {
                    errorToast(t("Call from table") + ": " + message.data.tableName);
                }
            })
        }

    }

    const isAuthenticated = () => {
        return !!(access_token);
    }

    const matchLogin = () => (
        !!matchPath(location.pathname, {
            path: "/login",
        })
    )

    const matchWaiter = () => (
        !!matchPath(location.pathname, {
            path: "/tables",
        })
    )

    const matchClient = () => (
        !!matchPath(location.pathname, {
            path: "/menu",
        })
    )

    const matchResetPassword = () => (
        !!matchPath(location.pathname, {
            path: "/reset-password",
        })
    )

    const matchLanding = () => (
        !!matchPath(location.pathname, {
            path: "/",
            exact: true
        })
    )

    const matchSearch = () => (
        !!matchPath(location.pathname, {
            path: "/search",
            exact: true
        })
    )

    const matchRegister = () => (
        !!matchPath(location.pathname, {
            path: "/register",
            exact: true
        })
    )
    
    
    const headerAndSidebarVisible = () => {
        return !matchLogin() && !matchClient() && !matchResetPassword() && !matchLanding() && !matchSearch() && !matchRegister()
    }

    const onLanguageChange = ({ key }) => {
        i18n.changeLanguage(key);
    }

    const menu = (
        <Menu onClick={onLanguageChange}>
            <Menu.Item key="en">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <UKIcon className="mr-3"/> English
                </a>
            </Menu.Item>
            <Menu.Item key="sq">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <AlbaniaIcon className="mr-3"/> Shqip
                </a>
            </Menu.Item>
            <Menu.Item key="fr">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <FranceIcon className="mr-3"/> Française
                </a>
            </Menu.Item>
            <Menu.Item key="nl">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <NetherlandsIcon className="mr-3"/> Nederlands
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <>

            {loading && <Spinner/>}

            {headerAndSidebarVisible() && <div className="wrapper">


                <Sidebar/>

                {/*<div className={`content-wrapper ${shouldShowMenuPreview() ? "show-menu-preview" : ""}`}>*/}
                {/*{shouldShowMenuPreview() &&<MenuPreview className="menu-preview" menuId={"92"} />}*/}

                <div className="content-wrapper">
                    {/*<Header/>*/}

                    <Switch>
                        <section className={classnames("content", { "waiter-content": matchWaiter() })}>

                            <Dropdown className="languages-dropdown" overlay={menu} trigger={['click']}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    {languageIcons[i18n.language]} {i18n.language.toUpperCase()}
                                </a>
                            </Dropdown>

                            {/*<AuthenticatedRoute exact path="/statistics" component={Statistics} authenticated={isAuthenticated()}/>*/}
                            <AuthenticatedRoute exact path="/menus" component={Menus} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute exact path="/menus/:menuId/submenus" component={Submenus} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute exact path="/menus/:menuId/submenus/:submenuId/products" component={Submenu} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute path="/qrcodes" component={Tables} authenticated={isAuthenticated()}/>
                            {/*<AuthenticatedRoute exact path="/daily" component={Dailies} authenticated={isAuthenticated()}/>*/}
                            {/*<AuthenticatedRoute exact path="/daily/:dailyId/products" component={Daily} authenticated={isAuthenticated()}/>*/}
                            <AuthenticatedRoute path="/tables" component={Waiter} authenticated={isAuthenticated()}/>
                            {/*<AuthenticatedRoute path="/banners" component={Banners} authenticated={isAuthenticated()}/>*/}
                            <AuthenticatedRoute path="/events" component={Events} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute exact path="/products" component={Products} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute path="/account" component={Account} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute path="/feedback" component={Feedback} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute path="/help" component={Help} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute path="/orders" component={Orders} authenticated={isAuthenticated()}/>
                            <AuthenticatedRoute path="/delivery" component={DeliveryOrders} authenticated={isAuthenticated()}/>

                            <Route
                                path="/preview-qr-code"
                                render={() => {
                                    return <TableQrCode logo={user_details?.logo}/>
                                }} />
                        </section>
                    </Switch>

                    {/*<Footer/>*/}

                </div>


            </div>}

            <Route exact path="/login"
                   render={() => (
                       !isAuthenticated()
                           ? <Login/>
                           : <Redirect to="/menus"/>
                   )}/>

            <Route exact path="/register"
                   render={() => (
                       !isAuthenticated()
                           ? <Register/>
                           : <Redirect to="/products"/>
                   )}/>

            <Route exact path="/" component={Landing}/>
            <Route path="/reset-password" component={Password}/>
            <Route path="/search" component={Search}/>
            <Route path="/menu/:businessId?/:table?" component={Client}/>
        </>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.app.loading,
        user_details: state.app.user_details,
        access_token: state.app.access_token,
        firebase_token: state.app.firebase_token,
        user_details_fetched: state.app.user_details_fetched
    }
}

const mapDispatchToProps = dispatch => ({
    getBusinessDetails: data => dispatch(getBusinessDetails(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
