import React from "react";
import "./Info.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import Image0 from "../../../assets/images/slider/0.jpg";
import Image1 from "../../../assets/images/slider/1.jpg";
import Image2 from "../../../assets/images/slider/2.jpg";
import Image3 from "../../../assets/images/slider/3.jpg";
import Image4 from "../../../assets/images/slider/4.jpg";
import Image5 from "../../../assets/images/slider/5.jpg";
import Corner from "../../../assets/images/corner-green.png"
import classNames from "classnames";
import {useTranslation} from "react-i18next";


const Info = () => {
    
    const {t, i18n} = useTranslation();
    
    return (
        <section id="info">
            <div className="content">
                <div className="left">
                    <div className="title">
                        {t("Why Menyja?")}
                    </div>
                    
                    <p className="text">
                        {t("info-text-1")}<br/>
                        {t("info-text-2")}<br/>
                        {t("info-text-3")}<br/>
                        <br/>
                        {t("info-text-4")}<br/>
                        {t("info-text-5")}
                    
                    </p>
                    
                    <div className="subtitle">
                        {t("With our solution you can:")}
                    </div>
                    
                    <ul>
                        <li>{t("info-list-1")}</li>
                        <li>{t("info-list-2")}</li>
                        <li>{t("info-list-3")}</li>
                        <li>{t("info-list-4")}</li>
                        <li>{t("info-list-5")}</li>
                        <li>{t("info-list-6")}</li>
                        <li>{t("info-list-7")}</li>
                    </ul>
                </div>
                <div className="right">
                    <div className="carousel-container">
                        
                        {/*<img src={Corner} alt="Corner" className="corner-top-left"/>*/}
                        {/*<img src={Corner} alt="Corner" className="corner-top-right"/>*/}
                        {/*<img src={Corner} alt="Corner" className="corner-bottom-right"/>*/}
                        {/*<img src={Corner} alt="Corner" className="corner-bottom-left"/>*/}

                        <Carousel
                            autoPlay
                            interval={2000}
                            renderThumbs={children => null}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev &&
                                <img onClick={onClickHandler} src={Corner} alt="previous" className="arrow-previous"/>
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext &&
                                <img onClick={onClickHandler} src={Corner} alt="next" className="arrow-next"/>
                            }
                            renderIndicator={(onClickHandler, isSelected, index, label) => {
                                // return (
                                //     <li className={classNames({active: isSelected})}
                                //         onClick={onClickHandler}>
                                //         <i />
                                //         <span className="number">{index}</span>
                                //     </li>
                                // );
                            }}>
                            <div>
                                <img src={Image0}/>
                            </div>
                            <div>
                                <img src={Image1}/>
                            </div>
                            <div>
                                <img src={Image2}/>
                            </div>
                            <div>
                                <img src={Image3}/>
                            </div>
                            <div>
                                <img src={Image4}/>
                            </div>
                            <div>
                                <img src={Image5}/>
                            </div>
                        </Carousel>
                    </div>
                
                </div>
            </div>
        </section>
    )
}

export default Info;
