import React, {Component} from "react";
import "./Register.scss";
import {connect} from "react-redux";
import {
    authenticateClient,
    checkMenuCreation,
    createSession,
    generateMenu,
    getBusinessDetails,
    registerSignUpBusiness,
    uploadMenu
} from "../../../actions/user";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import Input from "../../../components/Input/Input";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Step from "../../../components/Step/Step";
import {GoogleApiWrapper} from 'google-maps-react';
import classNames from "classnames";
import {businessExists, writeBusinessCookies} from "../../../actions/client";
import {getQueryParameter, setCookie} from "../../../utils";
import Spinner from "../../../components/Spinner/Spinner";
import * as constants from "../../../utils/constants";

class Register extends Component {
    constructor(props) {
        super(props);
        //setLoading(false)
        this.state = {
            currentStep: 0,
            key: "",
            signupDone: false,
            businessCreated: false,
            uploadedFile: null,
            imageUrls: [],
            menuPreviewUrl: "",
            menuResponse: {},
            showValidation: false,
            shortVersion: true,// Change this to show the full version of business detatils.
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            businessUsername: "",
            businessType: "RESTAURANTS",
            businessName: "",
            businessPhoneNumber: "",
            businessAddressLine: "",
            businessCity: "",
            businessCountry: "",
            businessLatitude: 42.659580,
            businessLongitude: 21.160420,
            startDayTime: "07:00:00",
            endDayTime: "23:00:00",
            radiusInMeters: 20
        }

    }

    componentDidMount() {

        const continueParm = getQueryParameter("continue", window.location.href);

        console.log("Continue param: " + continueParm);
        if (continueParm === "true") {
            this.setState({currentStep: 1});
            this.setState({signupDone: true});
            this.setState({businessCreated: true});
        }

        console.log("Calling Auth client");
        this.props.authenticateClient().then(res => {
            this.setState({accessToken: res.data.access_token});
            localStorage.setItem("client_access_token", res.data.access_token);
            console.log("Finished Auth client call");

            console.log("Calling business exists");
            this.props.businessExists().then(res => {
                if (!res.data) {
                    console.log("Finished business exists call");
                    this.props.writeBusinessCookies().then(({data}) => {
                        // alert("SETTING COOKIE")
                        setCookie(data.name, data.value, data.maxAge);
                    });
                }
            });
        });
    }

    componentWillUnmount() {
        console.log("Calling Auth client");
        this.props.authenticateClient().then(res => {
            this.setState({accessToken: res.data.access_token});
            localStorage.setItem("client_access_token", res.data.access_token);
            console.log("Finished Auth client call");

            console.log("Calling business exists");
            this.props.businessExists().then(res => {
                if (!res.data) {
                    console.log("Finished business exists call");
                    this.props.writeBusinessCookies().then(({data}) => {
                        // alert("SETTING COOKIE")
                        setCookie(data.name, data.value, data.maxAge);
                    });
                }
            });
        });
    }

    incrementStep = () => {
        this.setState({currentStep: this.state.currentStep + 1, showValidation: false});
    }

    handleSubmit = e => {
        e.preventDefault()

        if (this.formIsInvalid()) {
            this.setState({showValidation: true});
            return;
        }

        this.props.setLoading(true);
        const {username, password} = this.state;

    }


    handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        this.setState({uploadedFile: file});

        if (this.state.imageUrls.length >= 3) {
            alert("Limit of 3 images reached");
            return;
        }
        this.props.setLoading(true);
        this.props.uploadMenu(formData)
            .then(res => {
                console.log(res);
                this.props.setLoading(false);
                this.setState(prevState => ({
                    imageUrls: [...prevState.imageUrls, res.data],
                }));
            });
    }

    // createMenu = async () => {
    //     this.props.generateMenu(this.state)
    //         .then(res => {
    //             console.log(res);
    //             this.props.setLoading(false);
    //             if (res.data) {
    //                 this.setState({menuResponse: res.data});
    //                 const menuPreview = window.location.host + "/menu/" + res.data.business.id + "/1"
    //                 this.setState({menuPreviewUrl: menuPreview});
    //                 //Maybe we need to do a push, redirecting the user directly there after the menu is created
    //                 this.props.history.push('/menu/' + res.data.business.id + '/signup');
    //             } else {
    //                 alert("Error generating menu, try again!");
    //             }
    //         });
    // }

    handleMenuCreation = () => {
        // Step 1: Call generateMenu without await
        try {
            try {
                this.props.generateMenu(this.state);
            } catch (Exception) {
                console.log("Exception in generateMenu");
            }

            // Delay the start of the status checks to give time for generateMenu to initiate
            setTimeout(() => {
                // Step 2: Start a loop or interval to periodically check the status
                const checkInterval = setInterval(async () => {
                    const checkResponse = await this.props.checkMenuCreation();
                    console.log("-----Response from checkMenuCreation: " + checkResponse.data);

                    // Check if the response indicates that the menu creation is complete
                    if (checkResponse.data /* condition to determine if the menu is ready */) {
                        clearInterval(checkInterval); // Clear the interval
                        // Step 4: Handle the final response
                        // Process the response or proceed with the next steps
                        if (checkResponse.data && checkResponse.data !== "ERROR") {
                            this.props.setLoading(false)
                            this.setState({menuResponse: checkResponse.data});
                            this.setState({businessCreated: true});
                            const menuPreview = window.location.host + "/menu/" + checkResponse.data + "/1"
                            this.setState({menuPreviewUrl: menuPreview});
                            //Maybe we need to do a push, redirecting the user directly there after the menu is created
                            this.props.history.push('/menu/' + checkResponse.data + '/signup');
                        } else {
                            alert("Error generating menu, try again!");
                            this.props.setLoading(false)
                        }
                    }
                }, 2000); // Adjust the interval time as needed
            }, 1000); // Delay the start of checking (e.g., 1000 milliseconds)
        } catch (error) {
            // Handle any errors
            console.error('Error in menu creation process:', error);
            alert("Error generating menu, try again!");
            this.props.setLoading(false)
        }
    }


    handleRegistration = () => {
        console.log("Registration, redirect to login");
        this.setState({showValidation: false})
        this.props.setLoading(true);
        this.props.registerSignUpBusiness(this.state)
            .then(res => {
                console.log(res);
                localStorage.setItem("access_token", res.data.token);
                this.props.setLoading(false);
                //this.props.history.push("/menus"); //TODO: This was added to skip the subscription step.

            });
        // this.props.history.push("/login");
    }
    handleBack = () => {
        if (this.state.currentStep > 0) {
            this.setState({currentStep: this.state.currentStep - 1});
        }
    }

    handleClear = () => {
        this.setState({currentStep: 0});
        this.setState({imageUrls: []});
        this.props.authenticateClient().then(res => {
            this.setState({accessToken: res.data.access_token});
            localStorage.setItem("client_access_token", res.data.access_token);
            console.log("Finished Auth client call");
            console.log("Rewriting cookies");
            this.props.writeBusinessCookies().then(({data}) => {
                // alert("SETTING COOKIE")
                setCookie(data.name, data.value, data.maxAge);
            });
        });
    }

    handleNext = () => {
        const {currentStep} = this.state;
        if (currentStep > 3) {
            console.log("handleNext, currentStep greater than 3, skipping");
            return;
        }

        if (currentStep === 0) {
            console.log("handleNext Step 0, validating menu info");
            if (this.zeroStepIsInvalid()) {
                alert("Invalid menu file")
                return;
            } else {

                if (this.state.imageUrls.length !== 0) {
                    this.props.setLoading(true);
                    //this.createMenu();
                    this.handleMenuCreation();
                } else {
                    if (window.confirm("Skipping menu preview. Do you want to proceed?")) {
                        // Logic to handle image upload
                        this.incrementStep();
                    } else {
                        // Logic to proceed to the next step
                    }
                }
            }
            return;
        }

        if (currentStep === 1) {
            console.log("handleNext Step 1, validating personal info");
            if (this.firstStepIsInvalid()) {
                this.setState({showValidation: true});
                return;
            } else {
                this.handleRegistration();
                this.incrementStep();
            }
            return;
        }
        //
        // if (currentStep === 2) {
        //     console.log("handleNext Step 2, validating business info");
        //
        //     if (this.secondStepIsInvalid()) {
        //         this.setState({showValidation: true});
        //         return;
        //     } else {
        //         this.incrementStep();
        //     }
        //     return;
        // }

        if (currentStep === 2) {
            console.log("handleNext Step 2, subscription");

            if (this.thirdStepIsInvalid()) {
                this.setState({showValidation: true});
                return;
            } else {
                this.incrementStep();
            }
            return;
        }

    }

    zeroStepIsInvalid = () => {
        const {
            uploadedFile,
        } = this.state;
        return null; //return validate(inputValidations.registerUploadFileInput, uploadedFile);
    }

    firstStepIsInvalid = () => {
        const {
            firstName,
            lastName,
            email,
            password,
        } = this.state;
        return validate(inputValidations.registerFirstNameInput, firstName) ||
            validate(inputValidations.registerLastNameInput, lastName) ||
            validate(inputValidations.registerEmailInput, email) ||
            validate(inputValidations.registerPasswordInput, password);
    }

    secondStepIsInvalid = () => {
        const {
            businessName,
            businessAddressLine,
            businessPhoneNumber,
            businessUsername,
            businessCountry,
            businessCity,
        } = this.state;
        return validate(inputValidations.registerBusinessNameInput, businessName) ||
            validate(inputValidations.registerBusinessAddressInput, businessAddressLine) ||
            validate(inputValidations.registerBusinessPhoneInput, businessPhoneNumber) ||
            validate(inputValidations.registerBusinessUsernameInput, businessUsername) ||
            validate(inputValidations.registerBusinessCountryInput, businessCountry) ||
            validate(inputValidations.registerBusinessCityInput, businessCity);
    }

    thirdStepIsInvalid = () => {
        return null;
    }

    handleMonthlySubscription = () => {
        console.log("Subscribed to monthly plan")
        this.setState({ key: constants.MONTHLY }, () => {
            // This code will run after the state has been updated
            this.props.createSession(this.state)
                .then(response => {
                    // Assuming the URL is directly in the response body
                    const redirectUrl = response.data; // Adjust this based on the actual response structure
                    window.location.href = redirectUrl; // Redirect the user to the URL
                })
                .catch(error => {
                    console.error("An error occurred", error);
                    // Handle any errors here
                });
        });
    }

    handleYearlySubscription = () => {
        console.log("Subscribed to yearly plan");
        this.setState({ key: constants.YEARLY }, () => {
            // This code will run after the state has been updated
            this.props.createSession(this.state)
                .then(response => {
                    // Assuming the URL is directly in the response body
                    const redirectUrl = response.data; // Adjust this based on the actual response structure
                    window.location.href = redirectUrl; // Redirect the user to the URL
                })
                .catch(error => {
                    console.error("An error occurred", error);
                    // Handle any errors here
                });
        });
    }

    formIsInvalid = () => {
        const {
            firstName,
            lastName,
            email,
            password,
            businessName,
            businessAddressLine,
            businessPhoneNumber,
            businessUsername,
            businessCountry,
            businessCity,
            businessLatitude,
            businessLongitude,
        } = this.state;
        return validate(inputValidations.registerFirstNameInput, firstName) ||
            validate(inputValidations.registerLastNameInput, lastName) ||
            validate(inputValidations.registerEmailInput, email) ||
            validate(inputValidations.registerPasswordInput, password) ||
            validate(inputValidations.registerBusinessNameInput, businessName) ||
            validate(inputValidations.registerBusinessAddressInput, businessAddressLine) ||
            validate(inputValidations.registerBusinessPhoneInput, businessPhoneNumber) ||
            validate(inputValidations.registerBusinessUsernameInput, businessUsername) ||
            validate(inputValidations.registerBusinessCountryInput, businessCountry) ||
            validate(inputValidations.registerBusinessCityInput, businessCity) ||
            validate(inputValidations.registerBusinessLatitudeInput, businessLatitude) ||
            validate(inputValidations.registerBusinessLongitudeInput, businessLongitude);
    }

    handleRemoveImage = (index) => {
        this.setState(prevState => ({
            imageUrls: prevState.imageUrls.filter((_, i) => i !== index),
        }));
    }

    renderZeroStep = () => {
        const { t } = this.props;
        const {showValidation} = this.state;
        return (
            <> {setLoading.data && <Spinner/>}
                <div className="upload-section"> {/* Apply the upload-section class here */}
                    <div className="file-upload"> {/* Apply the file-upload class here */}
                        <input type="file"
                               id="file"
                               key="file"
                               onChange={(e) => this.handleImageUpload(e.target.files[0])}
                               onInvalid={(e) => alert("Invalid file")}
                        />
                        <label htmlFor="file" className="btn-2 subscribe-button"> {/* Use the button style from subscribe-button class */}
                            {t("Upload menu")}
                        </label>
                    </div>
                    {this.state.imageUrls.map((url, index) => (
                        <div key={index} className="image-container uploaded-image-preview"> {/* Use uploaded-image-preview for image container */}
                            <button className="remove-button upload-control-button" onClick={() => this.handleRemoveImage(index)}> {/* Style the remove button */}
                                {t("Remove")}
                            </button>
                            <img src={url} alt={`Uploaded menu ${index}`} className="uploaded-image"/>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    renderFirstStep = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        return (
            <> {setLoading.data && <Spinner/>}
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("First name")}
                            wrapperClassName="mb-24"
                            key="registerFirstNameInput"
                            id="registerFirstNameInput"
                            name="registerFirstNameInput"
                            placeholder={t("First name")}
                            value={this.state.firstName}
                            validations={[inputValidations.registerFirstNameInput, showValidation]}
                            onChange={(e) => this.setState({firstName: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Last name")}
                            wrapperClassName="mb-24"
                            key="registerLastNameInput"
                            id="registerLastNameInput"
                            name="registerLastNameInput"
                            placeholder={t("Last name")}
                            value={this.state.lastName}
                            validations={[inputValidations.registerLastNameInput, showValidation]}
                            onChange={(e) => this.setState({lastName: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Email")}
                            wrapperClassName="mb-24"
                            key="registerEmailInput"
                            id="registerEmailInput"
                            name="registerEmailInput"
                            placeholder={t("Email")}
                            value={this.state.email}
                            validations={[inputValidations.registerEmailInput, showValidation]}
                            onChange={(e) => this.setState({email: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            type="password"
                            label={t("Password")}
                            wrapperClassName="mb-24"
                            key="registerPasswordInput"
                            id="registerPasswordInput"
                            name="registerPasswordInput"
                            placeholder={t("Password")}
                            value={this.state.password}
                            validations={[inputValidations.registerPasswordInput, showValidation]}
                            onChange={(e) => this.setState({password: e.target.value})}/>
                    </div>
                </div>
            </>
        )
    }

    renderSecondStep = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        return (
            <> {setLoading.data && <Spinner/>}
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Business name")}
                            wrapperClassName="mb-24"
                            key="registerBusinessNameInput"
                            id="registerBusinessNameInput"
                            name="registerBusinessNameInput"
                            placeholder={t("Business name")}
                            value={this.state.businessName}
                            validations={[inputValidations.registerBusinessNameInput, showValidation]}
                            onChange={(e) => this.setState({businessName: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Business address")}
                            wrapperClassName="mb-24"
                            key="registerBusinessAddressInput"
                            id="registerBusinessAddressInput"
                            name="registerBusinessAddressInput"
                            placeholder={t("Business address")}
                            value={this.state.businessAddressLine}
                            validations={[inputValidations.registerBusinessAddressInput, showValidation]}
                            onChange={(e) => this.setState({businessAddressLine: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Business phone")}
                            wrapperClassName="mb-24"
                            key="registerBusinessPhoneInput"
                            id="registerBusinessPhoneInput"
                            name="registerBusinessPhoneInput"
                            placeholder={t("Business phone")}
                            value={this.state.businessPhoneNumber}
                            validations={[inputValidations.registerBusinessPhoneInput, showValidation]}
                            onChange={(e) => this.setState({businessPhoneNumber: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Business username")}
                            wrapperClassName="mb-24"
                            key="registerBusinessUsernameInput"
                            id="registerBusinessUsernameInput"
                            name="registerBusinessUsernameInput"
                            placeholder={t("Business username")}
                            value={this.state.businessUsername}
                            validations={[inputValidations.registerBusinessUsernameInput, showValidation]}
                            onChange={(e) => this.setState({businessUsername: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Business country")}
                            wrapperClassName="mb-24"
                            key="registerBusinessCountryInput"
                            id="registerBusinessCountryInput"
                            name="registerBusinessCountryInput"
                            placeholder={t("Business country")}
                            value={this.state.businessCountry}
                            validations={[inputValidations.registerBusinessCountryInput, showValidation]}
                            onChange={(e) => this.setState({businessCountry: e.target.value})}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input
                            label={t("Business city")}
                            wrapperClassName="mb-24"
                            key="registerBusinessCityInput"
                            id="registerBusinessCityInput"
                            name="registerBusinessCityInput"
                            placeholder={t("Business city")}
                            value={this.state.businessCity}
                            validations={[inputValidations.registerBusinessCityInput, showValidation]}
                            onChange={(e) => this.setState({businessCity: e.target.value})}/>
                    </div>
                    {/*<div className="col-12">*/}
                    {/*    <Input*/}
                    {/*        type="textarea"*/}
                    {/*        label={`${t("Description")}`}*/}
                    {/*        wrapperClassName="mb-24"*/}
                    {/*        key="registerDescriptionInput"*/}
                    {/*        id="registerDescriptionInput"*/}
                    {/*        name="registerDescriptionInput"*/}
                    {/*        placeholder={t("Description")}*/}
                    {/*        value={this.state.description}*/}
                    {/*        validations={[inputValidations.registerDescriptionInput, showValidation]}*/}
                    {/*        onChange={(e) => this.setState({ description: e.target.value })}/>*/}
                    {/*</div>*/}
                </div>
            </>
        )
    }

    renderThirdStep = () => {
        const {t} = this.props;
        const {showValidation} = this.state;
        return (
            <> {setLoading.data && <Spinner/>}
                <div className="subscription-container">
                    <div className="subscription-option">
                        <div className="subscription-details">
                            <div className="subscription-title">{t("Yearly Plan")}</div>
                            <div className="subscription-price">{t("190.00")}{t("$")}</div>
                            <div className="subscription-description">{t("Get two months free with our annual subscription plan.")}
                            </div>
                            <button className="subscribe-button"
                                    onClick={this.handleYearlySubscription}>{t("Subscribe")}
                            </button>
                        </div>
                    </div>
                    <div className="subscription-option">
                        <div className="subscription-details">
                            <div className="subscription-title">{t("Monthly Plan")}</div>
                            <div className="subscription-price">{t("19.00")}{t("$")}</div>
                            <div className="subscription-description">{t("Enjoy full access to our features on a monthly basis.")}
                            </div>
                            <button className="subscribe-button"
                                    onClick={this.handleMonthlySubscription}>{t("Subscribe")}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderSteps = () => {
        switch (this.state.currentStep) {
            case 0: {
                console.log("Step 0, rendering menu upload");
                return this.renderZeroStep()
            }
            case 1: {
                console.log("Step 1, rendering user info");
                return this.renderFirstStep()
            }
            case 2: {
                console.log("Step 2, rendering business info");
                return this.renderThirdStep()
            }
            // case 3: {
            //     console.log("Step 3, rendering subscription");
            //     return this.renderThirdStep()
            // }

            case 3: {
                 console.log("Step 4, nothing");
                 return ;
             }
        }
    }

    render() {

        const {currentStep} = this.state;
        const {t} = this.props;

        return (
            <> {setLoading.data && <Spinner/>}
                <div className={classNames("register-box")}>

                    {currentStep !== 4 &&
                        <div className="d-flex justify-content-between align-items-center w-50 mx-auto mb-32 step-indicator">
                        <Step text={1} current={currentStep === 0} finished={currentStep > 0} className="step"/>
                            <div className="steps-line"/>
                            <Step text={2} current={currentStep === 1} finished={currentStep > 1} className="step" />
                            <div className="steps-line"/>
                            {/*<Step text={3} current={currentStep === 2} finished={currentStep > 2} className="step"/>*/}
                            {/*<div className="steps-line"/>*/}
                            <Step text={3} current={currentStep === 2} finished={currentStep > 2} className="step"/>
                        </div>}
                    {/*<p className="text-center">Welcome to</p>*/}
                    {currentStep === 0 && <>
                        <h1 className="text-center mb-48">{t("Upload your menu")}</h1>
                        <h4 className="text-center mb-48">Make sure to take clear images of your menu</h4>
                    </>
                    }
                    {currentStep === 1 && <h1 className="text-center mb-48">{t("Provide your details")}</h1>}
                    {/*{currentStep === 2 && <h1 className="text-center mb-48">{t("Provide business details")}</h1>}*/}
                    {currentStep === 2 && <h1 className="text-center mb-48">{t("Chose your plan")}</h1>}
                    <div className="login-box-body">

                        <form onSubmit={e => e.preventDefault()} method="post">


                            {this.renderSteps()}

                            <div className="bottom-container">
                                {/*{(currentStep === 0 || currentStep === 1|| currentStep === 2) && <Link className={currentStep === 3 ? "mx-auto" : "mx-auto"} to="/login">{t("Log in")}</Link>}*/}
                                {(currentStep === 0 || currentStep === 1) &&
                                    <Link className="login-link" to="/login">{t("Log in")}</Link>}

                                {currentStep !== 3 && <div>
                                    {(currentStep === 1 || currentStep === 2)&& <Button
                                        type={ButtonTypes.Filled}
                                        color={ButtonColors.Green}
                                        onClick={(e) => this.handleBack(e)}>
                                        {t("Back")}
                                    </Button>}
                                    {currentStep === 0 && <Button
                                        type={ButtonTypes.Filled}
                                        color={ButtonColors.Green}
                                        onClick={(e) => this.handleClear(e)}>
                                        {t("Reset")}
                                    </Button>}
                                    {(currentStep === 0 || currentStep === 1 )&&<Button
                                        className="ml-3"
                                        type={ButtonTypes.Filled}
                                        color={ButtonColors.Green}
                                        onClick={(e) => this.handleNext(e)}>
                                        {t("Next")}
                                    </Button>
                                    }
                                </div>}
                            </div>
                        </form>

                    </div>

                    {/*<ReactDrawer*/}
                    {/*    className="maps-drawer"*/}
                    {/*    open={this.state.currentStep === 3}*/}
                    {/*    handler={false}*/}
                    {/*    placement="right"*/}
                    {/*    level={null}*/}
                    {/*    getContainer={document.getElementById("root")}>*/}

                    {/*<Map*/}
                    {/*    zoom={14}*/}
                    {/*    google={this.props.google}*/}
                    {/*    initialCenter={{ lat: 42.659580, lng: 21.160420 }}*/}
                    {/*    onCenterChanged={(props, map) => {*/}
                    {/*        this.setState({ businessLatitude: map.center.lat(), businessLongitude: map.center.lng() })*/}
                    {/*    }}>*/}
                    {/*    <Marker*/}
                    {/*        name="marker"*/}
                    {/*        position={{*/}
                    {/*            lat: this.state.businessLatitude,*/}
                    {/*            lng: this.state.businessLongitude*/}
                    {/*        }}  />*/}
                    {/*</Map>*/}

                    {/*</ReactDrawer>*/}
                </div>
            </>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    registerSignUpBusiness: data => dispatch(registerSignUpBusiness(data)),
    createSession: data => dispatch(createSession(data)),
    uploadMenu: data => dispatch(uploadMenu(data)),
    generateMenu: data => dispatch(generateMenu(data)),
    checkMenuCreation: () => dispatch(checkMenuCreation()),
    businessExists: () => dispatch(businessExists()),
    writeBusinessCookies: () => dispatch(writeBusinessCookies()),
    getUserDetails: data => dispatch(getBusinessDetails(data)),
    authenticateClient: (data) => dispatch(authenticateClient(data)),
});

const translation = withTranslation();
const connector = connect(null, mapDispatchToProps);
const googleApiWrapper = GoogleApiWrapper({apiKey: "AIzaSyDUjYV920crnjF-BlA1cCapw9yyT4DUXjk"});
export default withRouter(connector(translation(googleApiWrapper(Register))));
