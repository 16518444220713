import React, {Component} from "react";
import "./Submenu.scss";
import {connect} from "react-redux";
import {
    addProductImage,
    addProducts,
    createProduct, getProductImages,
    getProducts,
    removeProductFromSubmenu, removeProductImage,
    setProducts, updateProduct,
    updateProductOrdering
} from "../../../actions/products";
import Modal from "../../../components/Modal/Modal";
import {askForConfirmation, editingTitle, prepareOrderingPayload, productExists, reorder, search} from "../../../utils";
import {getSubmenu} from "../../../actions/submenus";
import {Link, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons'
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import Button, {ButtonColors, ButtonTypes} from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import {
    EMPTY_PRODUCT_OBJECT, getBusinessId, getCurrencyLocally,
    PRODUCT_SORTING_TYPES,
    SUBMENU_MODAL_TYPES,
    SUBMENU_SIDEBAR_CONTENT
} from "../../../utils/constants";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Drawer from "../../../components/Drawer/Drawer";
import CreateProductSidebar from "../../../components/Sidebars/CreateProductSidebar/CreateProductSidebar";
import EditProductSidebar from "../../../components/Sidebars/EditProductSidebar/EditProductSidebar";
import AddProductsSidebar from "../../../components/Sidebars/AddProductsSidebar/AddProductsSidebar";
import MenuPreview from "../../../components/Preview/MenuPreview";

class Submenu extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";
    
    constructor(props) {
        super(props);
        
        this.state = {
            isMobile: (window.innerWidth < 768),
            showValidation: false,
            menu_title: "",
            submenu_title: "",
            submenu_products: [],
            selected_products: [],
            modal_content: SUBMENU_MODAL_TYPES.ADD,
            isModalOpen: false,
            search_text: "",
            sidebar_opened: false,
            product: {
                name: "",
                nameEn: "",
                price: "",
                description: "",
                descriptionEn: "",
                preparingTime: "",
                images: []
            },
        }
    }
    
    sortingDropdownOptions = [
        {
            value: PRODUCT_SORTING_TYPES.RATING_HIGHEST,
            label: this.props.t("Rating (highest)")
        },
        {
            value: PRODUCT_SORTING_TYPES.RATING_LOWEST,
            label: this.props.t("Rating (lowest)")
        }
    ]
    updateWindowDimensions = () => {
        this.setState({ isMobile: window.innerWidth < 768 });
    };
    
    componentDidMount() {
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        window.addEventListener('resize', this.updateWindowDimensions);

        if (this.props.menus) {
            const menu = this.props.menus.find(menu => menu.id == menuId);
            if (menu) {
                this.setState({menu_title: menu.title});
            }
        }

        const {products} = this.props;
        this.props.getProducts();
        this.updateSubmenuProducts();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    submenu_products = (products) => {
        return products.sort((a, b) => a.order - b.order);
    }
    
    closeModal = () => {
        this.setState({
            showValidation: false,
            isModalOpen: false,
            sidebar_opened: false,
            selected_products: [],
            sidebar_content: SUBMENU_SIDEBAR_CONTENT.CREATE_PRODUCT,
            product: EMPTY_PRODUCT_OBJECT
        });
    }



    clearProduct = () => {
        this.setState({
            product: EMPTY_PRODUCT_OBJECT
        });
    }

    handleAddProducts = (products) => {//EDOEDO
        const {t} = this.props;
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        
        this.props.setLoading(true);
        this.props.addProduct(menuId, submenuId, products)
                    .then(res => this.updateSubmenuProducts());
    }

    updateSubmenuProducts = () => {
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        this.props.getProducts();
        this.props.getSubmenu(menuId, submenuId).then(res => {
            const products = this.submenu_products(res.data.products);
            const title = res.data.title;
            this.setState({submenu_products: products, submenu_title: title});
            this.closeModal();
            this.props.setLoading(false);
        })
    }
    
    onClickDelete = async(item, e) => {
        e.stopPropagation();
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        const productId = item.id;
        const {t} = this.props;
        
        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }
        
        this.props.setLoading(true);
        this.props.removeProductFromSubmenu(menuId, submenuId, productId)
                .then(res => this.updateSubmenuProducts());
     }
    
    handleSelectProduct = e => {
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        let {selected_products} = this.state;
        if (checked && !selected_products.includes(value)) {
            selected_products.push(value);
        } else if (!checked && selected_products.includes(value)) {
            selected_products.splice(selected_products.indexOf(value), 1);
        }
        this.setState({selected_products});
    }
    
    handleUploadImage = e => {
        if (!(e && e.target && e.target.files && e.target.files[0])) {
            return;
        }
    
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        
        let file = e.target.files[0];
        let productId = this.state.product.id;
        this.props.setLoading(true);
        this.props.addProductImage(productId, file).then(res => {
            this.props.getProducts();
            this.props.getProductImages(productId).then(res => {
                this.setState({product: {...this.state.product, images: res.data}})
                this.props.setLoading(false);
            })
            this.props.getSubmenu(menuId, submenuId).then(res => {
                this.setState({submenu_products: res.data.products});
                this.props.setLoading(false);
            })
        })
    }
    
    handleDeleteImage = image => {
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        let productId = this.state.product.id;
        this.props.setLoading(true);
        this.props.removeProductImage(productId, image.id).then(res => {
            this.props.getProducts();
            this.props.getProductImages(productId).then(res => {
                this.setState({product: {...this.state.product, images: res.data}})
                this.props.setLoading(false);
            })
            this.props.getSubmenu(menuId, submenuId).then(res => {
                const products = this.submenu_products(res.data.products);
                this.setState({submenu_products: products});
                this.props.setLoading(false);
            })
        })
    }
    
    
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        console.log("result", result);
        
        const submenu_products = reorder(
            this.state.submenu_products,
            result.source.index,
            result.destination.index
        );
        
        const menuId = this.props.match.params.menuId;
        const submenuId = this.props.match.params.submenuId;
        const reorderedProductsPayload = prepareOrderingPayload(submenu_products, "productId");
        this.props.updateProductOrdering(menuId, submenuId, reorderedProductsPayload);
        
        this.setState({submenu_products});
    }

    openCreateProductSidebar = () => {
        this.clearProduct();
        this.setState({
            sidebar_content: SUBMENU_SIDEBAR_CONTENT.CREATE_PRODUCT,
            sidebar_opened: true,
        });
    }

    openEditProductSidebar = (product, e) => {
        e.stopPropagation();
        this.clearProduct();
        this.setState({
            product: product,
            sidebar_content: SUBMENU_SIDEBAR_CONTENT.EDIT_PRODUCT,
            sidebar_opened: true,
        });
    }

    openAddProductsSidebar = () => {
        this.setState({
            sidebar_content: SUBMENU_SIDEBAR_CONTENT.ADD_PRODUCTS,
            sidebar_opened: true,
        });
    }

    render() {
        const {isModalOpen, search_text, modal_content, product, showValidation, sidebar_opened, sidebar_content} = this.state;
        const submenu_products = search(this.state.submenu_products, search_text, "name");
        const {submenu, t} = this.props;
        const products = search(this.props.products, search_text, "name");
        const create = modal_content === SUBMENU_MODAL_TYPES.CREATE;
        const edit = modal_content === SUBMENU_MODAL_TYPES.EDIT;
        const add = modal_content === SUBMENU_MODAL_TYPES.ADD;
        const isImagesModal = modal_content === SUBMENU_MODAL_TYPES.IMAGES;
        
        return (
            <div className="submenu-page">

                <div className="submenu-layout">
                    {!this.state.isMobile &&<MenuPreview className="menu-preview" menuId={getBusinessId()}/>}

                    <div className="submenu-content">
                <div className="page-header-row">
                    <button
                        onClick={() => this.props.history.goBack()}
                        className="back-button"
                        aria-label="Go back to Menus">
                        {t("Submenus")}/
                    </button>
                    <h1 className="products-text">{t("Products")}</h1>
                    <div className="d-flex">
                        <Button
                            className="mr-16"
                            color={ButtonColors.Green}
                            onClick={() => this.openAddProductsSidebar()}>
                            + {t("Add Products")}
                        </Button>
                        <Button
                            color={ButtonColors.Green}
                            onClick={() => this.openCreateProductSidebar()}>
                            + {t("Create Product")}
                        </Button>
                    </div>
                </div>

                <div className="page-search-row">
                    <Input
                        type="text"
                        id="searchTablesInput"
                        name="searchTablesInput"
                        placeholder={t("Search for products")}
                        value={search_text}
                        onChange={(e) => this.setState({search_text: e.target.value})}/>
                </div>
                
                <div className="box-body">
                    {submenu_products.length > 0 ?
                        <DragAndDrop
                            items={submenu_products}
                            type={DraggableTypes.PRODUCT}
                            onClickImages={(item, e) => this.onClickImages(item, e)}
                            onClickEdit={(item, e) => this.openEditProductSidebar(item, e)}
                            onClickDelete={(item, e) => this.onClickDelete(item, e)}
                            onDragEnd={(result) => this.onDragEnd(result)}/> : t("No products") + "..."}
                </div>

                <Drawer
                    open={sidebar_opened}>


                    {sidebar_content === SUBMENU_SIDEBAR_CONTENT.ADD_PRODUCTS &&
                    <AddProductsSidebar
                        submenuProducts={submenu_products}
                        onSubmit={(products) => this.handleAddProducts(products)}
                        onCloseButtonClick={this.closeModal}/>}

                    {sidebar_content === SUBMENU_SIDEBAR_CONTENT.CREATE_PRODUCT &&
                    <CreateProductSidebar
                        onSubmit={(product) => this.handleAddProducts([product.id])}
                        onCloseButtonClick={this.closeModal}/>}

                    {sidebar_content === SUBMENU_SIDEBAR_CONTENT.EDIT_PRODUCT &&
                    <EditProductSidebar
                        product={product}
                        onSubmit={() => this.updateSubmenuProducts()}
                        onCloseButtonClick={this.closeModal}/>}

                </Drawer>
                
                <Modal
                    isModalOpen={isModalOpen}
                    handleClose={this.closeModal}
                    handleSubmit={() => add ? this.handleAddProducts() : edit ? this.handleUpdateProduct() : create ? this.handleCreateProduct() : this.closeModal()}
                    title={add ? t("Add Products") : create ? t("Create and Add Product") : editingTitle(product.name) }>
                    
                    {add && <div className="submenu-products-container">
                        
                        <Input
                            autoComplete="off"
                            label={t("Search")}
                            value={search_text}
                            id="search-input"
                            name="search-input"
                            onChange={(e) => this.setState({search_text: e.target.value})}/>
                        
                        <div className="list-group">
                            {products.map((product, index) => (
                                !productExists(submenu_products, product.id) &&
                                <div className="list-group-item list-group-item-action py-1 pl-2 pr-3">
                                    <div className="row">
                                        <div className="col-1">
                                            <input type="checkbox"
                                                   className="h-100"
                                                   id={"product-" + index}
                                                   value={product.id}
                                                   onChange={(e) => this.handleSelectProduct(e)}/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor={"product-" + index}
                                                   className="w-100 m-0">
                                                {product.name}
                                                <span className="float-right text-right">{product.price}{getCurrencyLocally()}</span>
                                                <br/>
                                                <span
                                                    className="text-secondary text-sm">{product.description}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}
    
    
                    {isImagesModal &&
                    <div className="product-images">
                        {!!(product.images && product.images.length) &&
                        product.images.map(image => {
                            return (
                                <div className="product-image-box">
                                    <div className="delete-image-button"
                                         onClick={() => this.handleDeleteImage(image)}>
                                        <FontAwesomeIcon icon={faTimes} color="white"/>
                                    </div>
                                    <img src={image.link} alt="Image" className="product-image"/>
                                </div>
                            )
                        })}
                        {!!(product && product.images && product.images.length < 5 || product.images == null) &&
                        <>
                            <div className="product-add-image-button"
                                 onClick={() => this.imageInputRef.click()}>
                                <FontAwesomeIcon icon={faPlus} color="white"/>
                            </div>
                            <input className="d-none"
                                   type="file"
                                   ref={ref => this.imageInputRef = ref}
                                   onChange={e => this.handleUploadImage(e)}/>
                        </>}
                    </div>}
                    
                </Modal>
            
            </div>
            </div>
            </div>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        products: state.app.products,
        submenu: state.app.submenu,
        menus: state.app.menus
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    setProducts: data => dispatch(setProducts(data)),
    getProducts: data => dispatch(getProducts(data)),
    createProduct: data => dispatch(createProduct(data)),
    updateProduct: (productId, data) => dispatch(updateProduct(productId, data)),
    addProduct: (menuId, submenuId, data) => dispatch(addProducts(menuId, submenuId, data)),
    getSubmenu: (menuId, submenuId) => dispatch(getSubmenu(menuId, submenuId)),
    removeProductFromSubmenu: (menuId, submenuId, productId) => dispatch(removeProductFromSubmenu(menuId, submenuId, productId)),
    updateProductOrdering: (menuId, submenuId, data) => dispatch(updateProductOrdering(menuId, submenuId, data)),
    getProductImages: (productId) => dispatch(getProductImages(productId)),
    addProductImage: (productId, data) => dispatch(addProductImage(productId, data)),
    removeProductImage: (productId, imageId) => dispatch(removeProductImage(productId, imageId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Submenu)));
