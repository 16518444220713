import axios from "axios";
import * as constants from "../utils/constants";
import {BUSINESS_COOKIE_NAME, COOKIE_NAME, COOKIE_UNKNOWN} from "../utils/constants";
import {getCookie} from "../utils";

const url = process.env.REACT_APP_BACKEND_URL;

export const login = data => {
    return function(dispatch) {
        return axios.post(url + "oauth/token", `grant_type=password&username=${data.username}&password=${data.password}`, {
            headers: {
                "Authorization": "Basic MEdTTlRjYmxhUDNmZ2lUOlQwMnhrVEdLdjA2bktzOUhT",
                "Content-Type": "application/x-www-form-urlencoded",
            }
        }).then(res => {
            dispatch({ type: constants.LOGIN, payload: res.data });
            return res;
        })
    }
}

export const registerSignUpBusiness = data => {
    const token = localStorage.getItem("client_access_token");
    let cookieValue;
    if(!data.businessCreated){
         cookieValue = COOKIE_UNKNOWN;
    }else{
        cookieValue = getCookie(BUSINESS_COOKIE_NAME);
    }
    return function(dispatch) {
        return axios.post(url + "users/businesses/signup", data, {
            headers: {
                "Authorization": "Bearer " + token,
                [BUSINESS_COOKIE_NAME]: cookieValue
            }
        }).then(res => {
            dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}
export const uploadMenu = data => {
    let token = localStorage.getItem("client_access_token");
    if(!token){
        token = localStorage.getItem("access_token");
    }
    return function(dispatch) {
        return axios.post(url + "init/media/images", data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}

export const createSession = data => {
    const  token = localStorage.getItem("access_token");

    return function(dispatch) {
        return axios.post(url + "stripe/create-session", data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}

export const generateMenu = data => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + "init/menus", data, {
            headers: {
                "Authorization": "Bearer " + token ,
                [BUSINESS_COOKIE_NAME]: getCookie(BUSINESS_COOKIE_NAME)
            }
        }).then(res => {
            // dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}
export const generateMenuFromBusiness = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "init/business/menus", data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            // dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}

export const generateSubMenuFromBusiness = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "init/business/submenus", data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            // dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}


export const checkMenuCreation = () => {
    const token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.get(url + "init/menus/check", {
            headers: {
                "Authorization": "Bearer " + token ,
                [BUSINESS_COOKIE_NAME]: getCookie(BUSINESS_COOKIE_NAME)
            }
        }).then(res => {
            dispatch({ type: constants.REGISTER, payload: res.data });
            return res;
        })
    }
}

export const checkBusinessMenuCreation = () => {
    const token = localStorage.getItem("access_token");
    const menuUid = localStorage.getItem("menuUuid");
    return function(dispatch) {
        return axios.get(url + "init/business/menus/check?menuId="+ menuUid, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        })
    }
}
export const checkBusinessSubMenuCreation = () => {
    const token = localStorage.getItem("access_token");
    const menuUid = localStorage.getItem("menuUuid");
    return function(dispatch) {
        return axios.get(url + "init/business/menus/check?menuId="+ menuUid, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        })
    }
}

export const getActiveSubscription = () => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + "stripe/subscription", {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        })
    }
}
export const manageSubscription = () => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.post(url + "stripe/create-portal-session", null,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        })
    }
}

export const authenticateClient = data => {
    return function(dispatch) {
        return axios.post(url + "oauth/token", `grant_type=client_credentials`, {
            headers: {
                "Authorization": "Basic MEdTTlRjYmxhUDNmZ2lUOlQwMnhrVEdLdjA2bktzOUhT",
                "Content-Type": "application/x-www-form-urlencoded",
            }
        }).then(res => {
            return res;
        })
    }
}

export const logout = data => ({
    type: constants.LOGOUT, payload: data
})

export const getBusinessDetailsClient = businessId => {
    const token = localStorage.getItem("client_access_token");
    return axios.get(url + `out/businesses/${businessId}`, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }).then(res => {
                localStorage.setItem("businessCurrency", res.data.configuration.currency);
                localStorage.setItem("businessId", res.data.id);
                return res;
            });
}

export const getUserDetails = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.get(url + `users/details`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const getBusinessDetails = data => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        dispatch({ type: constants.FETCHED_USER_DETAILS });
        return axios.get(url + `businesses/details`, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            localStorage.setItem("businessCurrency", res.data.configuration.currency);
            localStorage.setItem("businessId", res.data.id);
            dispatch({ type: constants.GET_BUSINESS_DETAILS, payload: res.data });
            return res;
        });
    }
}

export const updateBusinessDetails = (data, id) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/${id}`, data,{
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            localStorage.setItem("businessCurrency", res.data.configuration.currency);
            localStorage.setItem("businessId", res.data.id);
            return res;
        });
    }
}

export const updateBusinessAddress = (data, id) => {
    const token = localStorage.getItem("access_token");
    return function(dispatch) {
        return axios.put(url + `businesses/${id}/addresses`, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            return res;
        });
    }
}

export const sendPasswordResetEmail = data => {
    const client_token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `out/request-reset-password`, { email: data },{
            headers: {
                "Authorization": "Bearer " + client_token
            }
        }).then(res => {
            return res;
        });
    }
}

export const resetPassword = data => {
    const client_token = localStorage.getItem("client_access_token");
    return function(dispatch) {
        return axios.post(url + `out/reset-password`, data,{
            headers: {
                "Authorization": "Bearer " + client_token
            }
        }).then(res => {
            return res;
        });
    }
}

