import React, {Component} from "react";
import "./Menus.scss";
import {
    activateMenu,
    createMenu,
    deactivateMenu,
    deleteMenu,
    getMenus,
    setMenus,
    updateMenu,
    updateMenuOrdering
} from "../../../actions/menus";
import {connect} from "react-redux";
import Modal from "../../../components/Modal/Modal";
import {Link, withRouter} from "react-router-dom";
import {
    askForConfirmation,
    editingTitle,
    getLanguageLabel,
    prepareOrderingPayload,
    reorder,
    search
} from "../../../utils";
import DragAndDrop, {DraggableTypes} from "../../../components/Draggable/DragAndDrop";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import * as constants from "../../../utils/constants";
import {withTranslation} from "react-i18next";
import {setLoading} from "../../../actions";
import {uploadMediaImage} from "../../../actions/media";
import Button, {ButtonColors, ButtonSizes, ButtonTypes} from "../../../components/Button/Button";
import Input, {InputStyles} from "../../../components/Input/Input";
import {inputValidations} from "../../../utils/validation/configurations";
import {validate} from "../../../utils/validation";
import Drawer from "../../../components/Drawer/Drawer";
import {ReactComponent as EditIcon} from "../../../assets/icons/edit.svg";
import {ReactComponent as QrCodeIcon} from "../../../assets/icons/qr-code.svg";
import {ReactComponent as CloseIcon} from "../../../assets/icons/x.svg";
import MenuPreview from '../../../components/Preview/MenuPreview';
import {addMenu, getBusinessId} from "../../../utils/constants";
import {checkBusinessMenuCreation, generateMenuFromBusiness, uploadMenu} from "../../../actions/user"; // Adjust the path as necessary

class Menus extends Component {
    popUpQuestion = "A jeni të sigurt të vazhdoni?";

    constructor(props) {
        super(props);

        this.state = {
            isMobile: (window.innerWidth < 768),
            showMenuPreview: false, // Add this line
            showValidations: false,
            isModalOpen: false,
            editing: false,
            file: null,
            sidebar_opened: false,
            upload_menu_sidebar_opened: false,
            search_text: "",
            menuUuid: null,
            imageUrls: [],
            menu: {
                title: "",
                titleEn: "",
                photoUrl: "",
            },
        }
    }
    toggleMenuPreview = () => {
        this.setState((prevState) => ({showMenuPreview: !prevState.showMenuPreview}));
    }

    updateWindowDimensions = () => {
        this.setState({ isMobile: window.innerWidth < 768 });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.getMenus();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({
            showValidation: true,
            upload_menu_sidebar_opened: false,
            sidebar_opened: false,
            editing: false,
            menu: constants.EMPTY_MENU_OBJECT,
            file: null
        });
    }

    handleMediaUpload = async (file) => {
        return this.props.uploadMediaImage(file).then(res => {
            return {
                photoUrl: res.data
            }
        })
    }

    handleCreateMenu = async () => {
        const {t} = this.props;
        let {menu, file} = this.state;

        if (this.formIsInvalid()) {
            this.setState({showValidations: true});
            return;
        }

        // if (!await askForConfirmation(t("Do you want to continue?"))) {
        //     return;
        // }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                menu = {...menu, ...res};
                this.props.createMenu(menu).then(res => {
                    this.props.getMenus();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.createMenu(menu).then(res => {
                this.props.getMenus();
                this.closeModal();
                this.props.setLoading(false);
            });
        }

    }

    handleUpdateMenu = async () => {
        const {t} = this.props;
        let {menu, file} = this.state;

        if (this.formIsInvalid()) {
            this.setState({showValidations: true});
            return;
        }

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        if (file) {
            this.handleMediaUpload(file).then(res => {
                menu = {...menu, ...res};
                this.props.updateMenu(menu.id, menu).then(res => {
                    this.props.getMenus();
                    this.closeModal();
                    this.props.setLoading(false);
                });
            })
        } else {
            this.props.updateMenu(menu.id, menu).then(res => {
                this.props.getMenus();
                this.closeModal();
                this.props.setLoading(false);
            });
        }
    }

    openMenu = (menu) => {
        this.props.history.push("/menus/" + menu.id + "/submenus");
    }

    onClickEdit = (item, e) => {
        e.stopPropagation();
        this.setState({editing: true, menu: item});
        this.openModal();
    }

    onClickDelete = async (item, e) => {
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deleteMenu(item.id).then(res => {
            this.props.getMenus();
            this.props.setLoading(false);
        });
    }

    onClickActivate = async (item, e) => {
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.activateMenu(item).then(res => {
            this.props.getMenus()
            this.props.setLoading(false);
        })
    }

    onClickDeactivate = async (item, e) => {
        console.log(item);
        e.stopPropagation();
        const {t} = this.props;

        if (!await askForConfirmation(t("Do you want to continue?"))) {
            return;
        }

        this.props.setLoading(true);
        this.props.deactivateMenu(item).then(res => {
            this.props.getMenus();
            this.props.setLoading(false);
        })
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) return;

        const menus = reorder(
            this.props.menus,
            result.source.index,
            result.destination.index
        );

        const reorderedMenusPayload = prepareOrderingPayload(menus, "menuId");
        this.props.updateMenuOrdering(reorderedMenusPayload);

        this.props.setMenus(menus);
    }

    formIsInvalid = () => {
        const {menu} = this.state;
        return validate(inputValidations.menuTitleInput, menu.title) || validate(inputValidations.menuTitleEnInput, menu.titleEn);
    }


    openCreateMenuSidebar = () => {
        this.setState({
            sidebar_opened: true,
            editing: false,
            upload_menu_sidebar_opened: false

        });
    }

    openUploadMenuSidebar = () => {
        this.setState({
            upload_menu_sidebar_opened: true,
            sidebar_opened: false,
            editing: false
        });
    }

    openEditMenuSidebar = (menu, e) => {
        e.stopPropagation();
        this.setState({
            menu: menu,
            sidebar_opened: true,
            editing: true
        });
    }
    handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        this.setState({uploadedFile: file});

        if (this.state.imageUrls.length >= 3) {
            alert("Limit of 3 images reached");
            return;
        }
        this.props.setLoading(true);
        this.props.uploadMenu(formData)
            .then(res => {
                console.log(res);
                this.props.setLoading(false);
                this.setState(prevState => ({
                    imageUrls: [...prevState.imageUrls, res.data]
                }));
            });
    }
    //handleBusinessMenuGeneration

    handleBusinessMenuGeneration = () => {
        if(this.state.imageUrls.length < 1){
            return;
        }
        this.props.setLoading(true);
        const menuUuid = localStorage.getItem("menuUuid") || crypto.randomUUID();
        localStorage.setItem("menuUuid", menuUuid);

        const requestBody = {
            menuUuid: menuUuid,
            imageUrls: this.state.imageUrls
        };
        try {
            this.props.generateMenuFromBusiness(requestBody);
            // Delay the start of the status checks to give time for generateMenu to initiate
            setTimeout(() => {
                let hasTimedOut = false;

                // Set a timeout for 1 minute (60000 milliseconds)
                const timeoutId = setTimeout(() => {
                    hasTimedOut = true;
                    clearInterval(checkInterval); // Clear the interval
                    this.props.setLoading(false);
                    alert("Time limit reached. Menu generation is taking longer than expected.");
                }, 60000);

                // Step 2: Start a loop or interval to periodically check the status
                const checkInterval = setInterval(async () => {
                    if (hasTimedOut) {
                        return; // Stop the interval callback from running if it has timed out
                    }

                    const checkResponse = await this.props.checkBusinessMenuCreation();
                    console.log("-----Response from checkMenuCreation: " + checkResponse.data);

                    // Check if the response indicates that the menu creation is complete
                    if (checkResponse && checkResponse.data /* condition to determine if the menu is ready */) {
                        clearTimeout(timeoutId); // Clear the timeout if the condition is met
                        clearInterval(checkInterval); // Clear the interval
                        this.props.setLoading(false);
                        localStorage.removeItem("menuUuid");
                        this.props.addMenu(checkResponse.data);
                        window.location.reload();
                        //TODO: Add the menu to the menus list
                        // this.setState({
                        //     imageUrls: [], // Clear the images if needed
                        //     // Here, update the state with the new menu data
                        //     menus: [...this.state.menus, checkResponse.data]
                        // });
                    }
                }, 2000); // Adjust the interval time as needed
            }, 1000); // Delay the start of checking (e.g., 1000 milliseconds)
        } catch (error) {
            // Handle any errors
            console.error('Error in menu creation process:', error);
            alert("Error generating menu, try again!");
            localStorage.removeItem("menuUuid");
            this.props.setLoading(false);
        }
    }


    handleRemoveImage = (index) => {
        this.setState(prevState => ({
            imageUrls: prevState.imageUrls.filter((_, i) => i !== index),
        }));
    }

    render() {
        const {isModalOpen, menu, editing, file, showValidations, sidebar_opened,upload_menu_sidebar_opened, search_text} = this.state;
        const {menus_fetched, t, user_details} = this.props;
        const menus = search(this.props.menus, search_text, "title");

        return (
            <div className="menus-page">

                <div className="menus-layout">
                    {!this.state.isMobile &&<MenuPreview className="menu-preview" menuId={getBusinessId()}/>}

                    <div className="menu-content">
                        <div className="page-header-row">
                            <h1>{t("Menus")}</h1>
                            <div className="d-flex">
                                <Button
                                    className="mr-16"
                                    color={ButtonColors.Green}
                                    onClick={() => this.openUploadMenuSidebar()}>
                                    + {t("Upload Menu")}
                                </Button>
                                <Button
                                    className="mr-16"
                                    color={ButtonColors.Green}
                                    onClick={() => this.openCreateMenuSidebar()}>
                                    + {t("Create Menu")}
                                </Button>
                            </div>
                        </div>

                        <div className="page-search-row">
                            <Input
                                type="text"
                                id="searchTablesInput"
                                name="searchTablesInput"
                                placeholder={t("Search for menus")}
                                value={search_text}
                                onChange={(e) => this.setState({search_text: e.target.value})}/>
                        </div>

                        <div className="box-body">

                            {menus_fetched ?
                                <>
                                    {menus.length > 0 ?
                                        <DragAndDrop
                                            items={menus}
                                            type={DraggableTypes.MENU}
                                            onClickItem={(item, e) => this.openMenu(item, e)}
                                            onClickEdit={(item, e) => this.openEditMenuSidebar(item, e)}
                                            onClickDelete={(item, e) => this.onClickDelete(item, e)}
                                            onClickActivate={(item, e) => this.onClickActivate(item, e)}
                                            onClickDeactivate={(item, e) => this.onClickDeactivate(item, e)}
                                            onDragEnd={(result) => this.onDragEnd(result)}/> : "No menus..."}
                                </>
                                : <div className="text-center py-3">
                                    <div className="spinner-border text-info"/>
                                </div>
                            }

                        </div>

                        <Drawer
                            open={sidebar_opened}>

                            <div className="d-flex justify-content-between mb-32">
                                <h2>{editing ? t("Edit Menu") : t("Create Menu")}</h2>
                                <div className="d-flex">
                                    <Button
                                        type={ButtonTypes.Icon}
                                        onClick={this.closeModal}>
                                        <CloseIcon/>
                                    </Button>
                                </div>
                            </div>

                            <Input
                                label={`${t("Title")} (${t("Primary language")})`}
                                id="menuTitleInput"
                                name="menuTitleInput"
                                value={menu.title}
                                style={InputStyles.Material}
                                validations={[inputValidations.menuTitleInput, showValidations]}
                                onChange={(e) => this.setState({menu: {...menu, title: e.target.value}})}/>

                            <Input
                                label={`${t("Title")} (${t("Secondary language")})`}
                                id="menuTitleInput"
                                name="menuTitleInput"
                                value={menu.titleEn}
                                style={InputStyles.Material}
                                validations={[inputValidations.menuTitleEnInput, showValidations]}
                                onChange={(e) => this.setState({menu: {...menu, titleEn: e.target.value}})}/>

                            {/*<Input*/}
                            {/*    label={t("Photo or Video")}*/}
                            {/*    type="file"*/}
                            {/*    id="menuImageInput"*/}
                            {/*    name="menuImageInput"*/}
                            {/*    value={file}*/}
                            {/*    style={InputStyles.Material}*/}
                            {/*    validations={[inputValidations.menuImageInput, showValidations]}*/}
                            {/*    onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>*/}


                            <Button
                                className="mt-24"
                                size={ButtonSizes.Full}
                                color={ButtonColors.Green}
                                type={ButtonTypes.Filled}
                                onClick={() => editing ? this.handleUpdateMenu() : this.handleCreateMenu()}>
                                {editing ? t("Edit Menu") : t("Create Menu")}
                            </Button>
                            {/*TODO:Fix the edit to change the label when editing menu*/}
                        </Drawer>

                        <Drawer
                            open={upload_menu_sidebar_opened}>

                            <div className="d-flex justify-content-between mb-32">
                                <h2>{t("Upload Menu")}</h2>
                                <div className="d-flex">
                                    <Button
                                        type={ButtonTypes.Icon}
                                        onClick={this.closeModal}>
                                        <CloseIcon/>
                                    </Button>
                                </div>
                            </div>
                            <div className="upload-section"> {/* Apply the upload-section class here */}
                                <div className="file-upload"> {/* Apply the file-upload class here */}
                                    <input type="file"
                                           id="file"
                                           key="file"
                                           onChange={(e) => this.handleImageUpload(e.target.files[0])}
                                           onInvalid={(e) => alert("Invalid file")}
                                    />
                                    <label htmlFor="file" className="btn-2 subscribe-button"> {/* Use the button style from subscribe-button class */}
                                        {t("Upload menu")}
                                    </label>
                                </div>
                                {this.state.imageUrls.map((url, index) => (
                                    <div key={index} className="image-container uploaded-image-preview"> {/* Use uploaded-image-preview for image container */}
                                        <button className="remove-button upload-control-button" onClick={() => this.handleRemoveImage(index)}> {/* Style the remove button */}
                                            {t("Remove")}
                                        </button>
                                        <img src={url} alt={`Uploaded menu ${index}`} className="uploaded-image"/>
                                    </div>
                                ))}
                            </div>



                            {/*<Input*/}
                            {/*    label={t("Photo or Video")}*/}
                            {/*    type="file"*/}
                            {/*    id="menuImageInput"*/}
                            {/*    name="menuImageInput"*/}
                            {/*    value={file}*/}
                            {/*    style={InputStyles.Material}*/}
                            {/*    validations={[inputValidations.menuImageInput, showValidations]}*/}
                            {/*    onChange={(e) => this.setState({file: e.target.files[0] ? e.target.files[0] : null})}/>*/}

                            <Button
                                className="mt-24"
                                size={ButtonSizes.Full}
                                color={ButtonColors.Green}
                                type={ButtonTypes.Filled}
                                onClick={() => this.handleBusinessMenuGeneration() }>
                                {t("Generate")}
                            </Button>
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        menus: state.app.menus,
        menus_fetched: state.app.menus_fetched,
        user_details: state.app.user_details,
    }
}

const mapDispatchToProps = dispatch => ({
    setLoading: (data) => dispatch(setLoading(data)),
    getMenus: data => dispatch(getMenus()),
    createMenu: data => dispatch(createMenu(data)),
    updateMenu: (menuId, data) => dispatch(updateMenu(menuId, data)),
    activateMenu: (data) => dispatch(activateMenu(data)),
    deactivateMenu: (data) => dispatch(deactivateMenu(data)),
    deleteMenu: menuId => dispatch(deleteMenu(menuId)),
    setMenus: data => dispatch(setMenus(data)),
    uploadMenu: data => dispatch(uploadMenu(data)),
    updateMenuOrdering: data => dispatch(updateMenuOrdering(data)),
    uploadMediaImage: (data) => dispatch(uploadMediaImage(data)),
    generateMenuFromBusiness: (data) => dispatch(generateMenuFromBusiness(data)),
    checkBusinessMenuCreation: data => dispatch(checkBusinessMenuCreation(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menus)));
