import React, { useState, useEffect } from 'react';
import './MenuPreview.scss';
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";

const MenuPreview = ({menuId}) => {
    const [iframeKey, setIframeKey] = useState(Date.now());
    const menuPreviewUrl = process.env.REACT_APP_MENU_PREVIEW_URL || 'https://menyja.co'; // Fallback URL


    const refreshIframe = () => {
        setIframeKey(Date.now());
    };

    return (
        <div className="menu-preview-container">
            <button className="menu-preview-refresh" onClick={refreshIframe}>
                <RefreshIcon />
            </button>
            <iframe
                key={iframeKey} // Ensure iframe refreshes by changing key
                className="menu-preview-iframe"
                src={`${menuPreviewUrl}/menu/${menuId}/1`}
                title="Menu Preview"
            />
        </div>
    );
    // useEffect(() => {
    //     // This will run on mount and every time the page refreshes
    //     setIframeKey(Date.now());
    // }, [/* Dependencies that when changed, indicate a page refresh */]);
    //
    // return (
    //     <div className="menu-preview-container">
    //         <iframe
    //             key={iframeKey} // Ensure iframe refreshes by changing key
    //             className="menu-preview-iframe"
    //             src={`${menuPreviewUrl}/menu/${menuId}/1`}
    //             title="Menu Preview"
    //         />
    //     </div>
    // );
};

export default MenuPreview;
