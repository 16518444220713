import React, {useEffect, useState} from "react";
import styles from "./Submenus.module.scss";
import {useHistory, useParams} from "react-router";
import {useSelector} from "react-redux";
import {getItemsPos, ScrollMenu, slidingWindow} from 'react-horizontal-scrolling-menu';
import SubmenusSliderItem from "../../../components/SubmenusSliderItem/SubmenusSliderItem";
import useDrag from "../../../components/HorizontalScrollingMenu/useDrag";
import {Element, scroller} from 'react-scroll'
import ProductCard from "../../../components/Cards/Product/ProductCard";
import {useTranslation} from "react-i18next";
import Carousel from "react-gallery-carousel";
import MenusSliderItem from "../../../components/MenusSliderItem/MenusSliderItem";
import {ReactComponent as Grid} from "../../../assets/icons/6-dots.svg";
import {ReactComponent as List} from "../../../assets/icons/list-view-2.svg";
import {ReactComponent as Arrow} from "../../../assets/icons/single-arrow.svg";
import {getTitleByLanguage, isTimeBetween} from "../../../utils";
import classnames from "classnames";
import {onWheel} from "../../../components/HorizontalScrollingMenu/onWheel";
import {LAYOUT_TYPES} from "../../../utils/constants";
import {submenuIcons} from "../../../components/SelectIcons/SelectIcons";

const Submenus = () => {

    const history = useHistory();
    let { businessId, table, menuId } = useParams();
    const {t, i18n} = useTranslation();

    const { dragStart, dragStop, dragMove, dragging } = useDrag();

    const menus = useSelector((state) => state.client.menus);
    const business = useSelector(state => state.client.business);
    const layout_type = useSelector(state => state.client.layout_type);

    const localSelectedMenuIndex = localStorage.getItem("selected_menu_index");
    const menuIndex = localSelectedMenuIndex && !isNaN(parseInt(localSelectedMenuIndex)) ? localSelectedMenuIndex : "0";
    const hasMenu = menus?.[menuIndex]?.subMenus;

    const [submenus, setSubmenus] = useState([]);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(hasMenu ? menuIndex : "0");
    const [selectedSubmenu, setSelectedSubmenu] = useState("");

    const [selectedView, setSelectedView] = useState("list");

    const [isScrolling, setIsScrolling] = useState(false);

    const isModalOpen = useSelector((state) => state.client.is_modal_open);

    const submenusRef = React.useRef({});

    const onDocumentScroll = (e) => {
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        // document.body.classList.add("overflow-hidden");
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;

        document.addEventListener('scroll',  onProductsContainerScroll);

        return () => {
            // document.body.classList.remove("overflow-hidden");
            document.removeEventListener('scroll',  onProductsContainerScroll);

        }
    }, [isScrolling]);

    useEffect(() => {
        if(selectedMenuIndex) {
            const submenus = menus[selectedMenuIndex]?.subMenus;
            setSubmenus(submenus || []);
        }
    }, [selectedMenuIndex]);

    useEffect(() => {
        if(submenus.length) {
            const localSubmenuId = localStorage.getItem("selected_submenu");
            const submenuExists = submenus.find(submenu => submenu.id.toString() === localSubmenuId);
            if(localSubmenuId && submenuExists) {
                console.log(selectedMenuIndex);
                scroller.scrollTo(`submenu-${localSubmenuId}`, {
                    duration: 0,
                    delay: 0,
                    offset: -20,
                    smooth: 'easeInOutQuint',
                    containerId: 'body'
                })
                setSelectedSubmenu(localSubmenuId)
            } else {
                const submenuToSelect = submenus.find((submenu) => isTimeBetween(submenu.startTime, submenu.endTime));
                if(submenuToSelect) {
                    setSelectedSubmenu(submenuToSelect["id"].toString());
                } else {
                    setSelectedSubmenu(submenus[0]["id"].toString());
                }
            }
        }
    }, [submenus]);


    const openProduct = (product) => {
        history.push(`/menu/${businessId}/${table}/products/${product.id}`);
    }

    const onProductsContainerScroll = e => {
        console.log(isScrolling);
        const elements = document.querySelectorAll(".scroll-to");
        for (let element of elements) {
            const parent = document.body;
            const active = element.dataset.scrollTo;
            const parentTop = parent.getBoundingClientRect().top;
            const elementBottom = element.getBoundingClientRect().bottom;
            const elementShouldBeActive = elementBottom - parentTop > 100;

            if (parent.offsetHeight + parent.scrollTop >= parent.scrollHeight) {
                const activeSubmenu = elements[elements.length - 1].dataset.scrollTo;
                localStorage.setItem("selected_submenu", activeSubmenu);
                setSelectedSubmenu(activeSubmenu)
                break;
            }
            if(elementShouldBeActive) {
                const container = document.querySelector('.selected-submenu');
                if(container) {
                    console.log(container)
                    const position = container.offsetLeft - 12;
                    document.querySelector('.submenus-scroll-container').scrollTo({ left: position, behavior: "auto" })
                }
                if(!isScrolling) {
                    localStorage.setItem("selected_submenu", active);
                    setSelectedSubmenu(active);
                }
                break;
            }
        }
    }

    const handleItemClick = (itemId) => ({ getItemById, scrollToItem}) => {
        if (dragging) {
            return false;
        }
        setIsScrolling(true);
        setTimeout(() => setIsScrolling(false), 2000);
        localStorage.setItem("selected_submenu", itemId);
        setSelectedSubmenu(itemId.toString());

        scroller.scrollTo(`submenu-${itemId}`, {
            duration: 200,
            delay: 0,
            offset: -20,
            smooth: 'linear',
            containerId: 'body'
        });
    };

    const handleDrag = ({ scrollContainer }) => (ev) => {
        console.log("scrolling");
        // dragMove(ev, (posDiff) => {
        //     if (scrollContainer.current) {
        //         scrollContainer.current.scrollLeft += posDiff;
        //     }
        // });

    }

    const firstSubmenu = submenus?.find(submenu => isTimeBetween(submenu.startTime, submenu.endTime));

    const renderRightArrowIcon = () => <Arrow style={{ width: "2.4rem", marginLeft: "2rem" }}/>;
    const renderLeftArrowIcon = () => <Arrow  style={{ width: "2.4rem", marginRight: "2rem", transform: "rotate(180deg)" }}/>;

    return (
        <>

            {!!selectedMenuIndex && <Carousel
                isLoop={false}
                hasIndexBoard={false}
                hasMediaButton={false}
                hasMediaButtonAtMax={false}
                hasSizeButton={false}
                hasDotButtons={false}
                hasThumbnails={false}
                shouldSwipeOnMouse={false}
                shouldMinimizeOnSwipeDown={false}
                hasRightButtonAtMax={false}
                hasLeftButtonAtMax={false}
                // hasLeftButton={false}
                // hasRightButton={false}
                rightIcon={renderLeftArrowIcon()}
                leftIcon={renderRightArrowIcon()}
                className={styles.menusSlider}
                index={parseInt(selectedMenuIndex)}
                objectFit="contain"
                onIndexChange={(index) => {
                    console.log(index);
                    localStorage.setItem("selected_menu_index", index.curIndex);
                    setSelectedMenuIndex(index.curIndex.toString());
                    const element = document.getElementById("scrollable-container");
                    element.scrollTop = 0;
                }}>
                {menus?.map(menu => <MenusSliderItem menu={menu} />)}
            </Carousel>}

            {!!submenus?.length &&
            <div onMouseLeave={dragStop}>
                <ScrollMenu
                    scrollContainerClassName="submenus-scroll-container"
                    wrapperClassName={styles.scrollMenuWrapper}
                    onWheel={onWheel}
                    onMouseDown={() => dragStart}
                    onMouseUp={({ getItemById, scrollToItem, visibleItems}) => () => {
                        dragStop();
                    }}
                    // options={{ throttle: 0 }}
                    onMouseMove={handleDrag}
                    apiRef={submenusRef}>
                    {submenus.map((submenu) => (
                        isTimeBetween(submenu.startTime, submenu.endTime) &&
                        <SubmenusSliderItem
                            onlyIcon={submenu.icon === submenuIcons.Star}
                            itemId={submenu.id} // NOTE: itemId is required for track items
                            submenu={submenu}
                            key={submenu.id}
                            onClick={handleItemClick(submenu.id)}
                            selected={submenu.id.toString() === selectedSubmenu}
                        />
                    ))}
                </ScrollMenu>
            </div>}

            <div
                id="scrollable-container"
                className={styles.productsContainer}
                onScroll={onProductsContainerScroll}>
                {submenus.map((submenu, index) =>
                    !!(submenu.products.length && isTimeBetween(submenu.startTime, submenu.endTime)) &&
                    <Element
                        className={classnames("scroll-to", "mt-24")}
                        data-scroll-to={submenu.id}
                        id={`submenu-${submenu.id}`}
                        name={`submenu-${submenu.id}`}>

                        <p className={styles.submenuTitle}>{getTitleByLanguage(i18n.language, business?.configuration.language, business?.configuration.secondaryLanguage, submenu)}</p>
                        <div className={classnames({[styles.submenusGrid]: layout_type === LAYOUT_TYPES.GRID})}>
                            {submenu.products.map((product, index) =>
                                <ProductCard
                                    gridView={layout_type === LAYOUT_TYPES.GRID}
                                    addToWishlist
                                    className="mb-12"
                                    product={product}
                                    onClick={() => openProduct(product)}/>)}
                        </div>
                    </Element>
                )}
            </div>
        </>
    );

}

export default Submenus;
