import React from "react";
import styles from "./ShowMoreModal.module.scss";
import {useTranslation} from "react-i18next";
import {isTimeBetween} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import DailyMenuCard from "../../Cards/DailyMenuCard/DailyMenuCard";
import PostCard from "../../Cards/PostCard/PostCard";
import {CLIENT_MODAL_TYPES} from "../../../utils/constants";
import {setDailyMenu, setEvent, openModal} from "../../../actions/client";
import Empty from "../../Empty/Empty";

const ShowMoreModal = () => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const business = useSelector(state => state.client.business);
    const daily_menus = useSelector(state => state.client.daily_menus);
    const events = useSelector(state => state.client.events);

    const dailyMenusExist = daily_menus?.some(daily_menu => isTimeBetween(daily_menu.startTime, daily_menu.endTime));
    const eventsExist =  !!events.length;

    const handleDailyMenuClick = (daily_menu) => {
        dispatch(setDailyMenu(daily_menu));
        dispatch(openModal(CLIENT_MODAL_TYPES.DAILY_MENU));
    }

    const handleEventClick = (event) => {
        dispatch(setEvent(event));
        dispatch(openModal(CLIENT_MODAL_TYPES.EVENT));
    }


    return (
        <div className={styles.showMoreModal}>
            {dailyMenusExist &&
            <div className={styles.title}>
                {t("Daily menus")}
            </div>}
            {daily_menus.map((daily_menu, index) =>
                <DailyMenuCard
                    className="mb-12"
                    daily_menu={daily_menu}
                    onClick={() => handleDailyMenuClick(daily_menu)}/>)}

            {eventsExist &&
            <div className={styles.title}>
                {t("Events")}
            </div>}
            {events.map((event, index) =>
                <PostCard
                    className="mb-12"
                    post={event}
                    onClick={() => handleEventClick(event)}/>)}

            {(!dailyMenusExist && !eventsExist) && (
                <div className="mt-94 text-center">
                    <Empty />
                    <p className="mt-16">{t("No daily menus or posts")}</p>
                    <p className="text-tertiary">{t("It looks like business does not have any thing to show at the moment", {name: business.name})}</p>
                </div>
            )}
        </div>
    )
}

export default ShowMoreModal;
