import * as constants from "../utils/constants/index";
import {CLIENT_MODAL_TYPES, LAYOUT_TYPES} from "../utils/constants/index";


const layout = localStorage.getItem("unem_layout_type") || LAYOUT_TYPES.GRID;

const initialState = {
    menus: [],
    daily_menus: [],
    events: [],
    banners: [],
    ratings: [],
    orders: [],
    delivery_orders: [],
    businesses: [],
    wishlist: 0,
    business: null,
    daily_menu: null,
    event: null,
    image: null,
    product: null,
    is_modal_open: false,
    is_popup_open: false,
    is_ordering_enabled: false,
    is_delivery_enabled: false,
    layout_type: layout,
    modal_type: CLIENT_MODAL_TYPES.WISHLIST
}

export const client = (state = initialState, action) => {
    switch (action.type) {

        case constants.GET_MENUS:
            return {
                ...state,
                menus: action.payload
            };

        case constants.GET_DAILY_MENUS:
            return {
                ...state,
                daily_menus: action.payload
            };

        case constants.GET_EVENTS:
            return {
                ...state,
                events: action.payload
            };

        case constants.GET_BANNERS:
            return {
                ...state,
                banners: action.payload
            };

        case constants.GET_BUSINESS:
            return {
                ...state,
                business: action.payload,
                is_ordering_enabled: !!action.payload.configuration?.enableOrder
            };

        case constants.GET_BUSINESSES:
            return {
                ...state,
                businesses: action.payload,
            };

        case constants.UPDATE_WISHLIST:
            return {
                ...state,
                wishlist: ++state.wishlist
            };

        case constants.SET_DAILY_MENU:
            return {
                ...state,
                daily_menu: action.payload
            };

        case constants.SET_EVENT:
            return {
                ...state,
                event: action.payload
            };

        case constants.SET_PRODUCT:
            return {
                ...state,
                product: action.payload
            };

        case constants.SET_IMAGE:
            return {
                ...state,
                image: action.payload
            };

        case constants.OPEN_MODAL:
            return {
                ...state,
                is_modal_open: true,
                modal_type: action.payload
            };

        case constants.CLOSE_MODAL:
            return {
                ...state,
                is_modal_open: false
            };

        case constants.OPEN_POPUP:
            return {
                ...state,
                is_popup_open: true,
                modal_type: action.payload
            };

        case constants.CLOSE_POPUP:
            return {
                ...state,
                is_popup_open: false
            };

        case constants.GET_RATINGS:
            return {
                ...state,
                ratings: action.payload
            };

        case constants.GET_ORDERS:
            return {
                ...state,
                orders: action.payload
            };

        case constants.GET_DELIVERY_ORDERS:
            return {
                ...state,
                delivery_orders: action.payload
            };

        case constants.SET_DELIVERY_ENABLED:
            return {
                ...state,
                is_delivery_enabled: action.payload
            };
        case constants.SET_SIGNUP_ENABLED:
            return {
                ...state,
                is_signup_enabled: action.payload
            };

        case constants.SET_LAYOUT_TYPE:
            let layout;
            if(action.payload === LAYOUT_TYPES.GRID || action.payload === LAYOUT_TYPES.LIST) {
                localStorage.setItem("unem_layout_type", action.payload);
                layout = action.payload;
            } else {
                layout = LAYOUT_TYPES.GRID;
            }
            return {
                ...state,
                layout_type: action.payload
            };

        default:
            return state
    }
}
